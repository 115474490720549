import { ReactNode } from 'react'
import { cx } from 'utils'
import { Label } from './Label'
import { Row } from './Row'
import styles from './Table.module.scss'

type Props = {
  toggle?: () => void
  on?: boolean
  title?: ReactNode
  children?: ReactNode
  className?: string
}

export const Summary = ({ toggle, on, title, children, className }: Props) => (
  <Row
    label={<Label on={on} title={title} toggle={toggle} />}
    className={cx(styles.summaryRow, className)}
  >
    {children}
  </Row>
)
