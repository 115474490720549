import { putRequest } from 'utils'
import { getProviderLeadsUrl } from 'getUrl'

export const putProviderLead = ({ providerLeadId, payload }): Promise<any> => {
  return putRequest(
    getProviderLeadsUrl({
      id: providerLeadId,
      action: 'economic-models'
    }),
    payload.payload
  )
}
