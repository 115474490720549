import { Link } from '@reach/router'
import { formatDate, formatTime } from 'utils'
import { Button, Icon, TextInput } from 'components'
import { useEffect, useState } from 'react'
import styles from './SnapshotHistory.module.scss'
import { useProviderLeadContext } from 'contexts'
import { EconomicModelSnapshot, ProviderLead } from 'models'

type Props = {
  providerLead: ProviderLead
  snapshot: EconomicModelSnapshot
  setOn?: (on: boolean) => void
  setDisableSave?: (disableSave: boolean) => void
}

const TitleDisplay = ({providerLead, snapshot, setOn}) => {
  const onLinkClick = ()=> setOn(false);
  const to = `/details/${providerLead.id}/snapshot/${snapshot.id}`
  return (
    <div className={styles.snapshotTitle}>
      <Link to={to} onClick={onLinkClick}>
        {snapshot.description ? snapshot.description : 'Untitled'}
      </Link>
    </div>
  )
}

const EditTitle = ({snapshot, setEditSnapshot}) => {
  const currentTitle = snapshot.description ? snapshot.description : 'Untitled'
  const [title, setTitle] = useState(currentTitle)
  const { updateSnapshot } = useProviderLeadContext();
  const handleUpdateSnapshot = async () => {
    await updateSnapshot({
      snapshotId: snapshot.id,
      description: title
    })
    setEditSnapshot(false)
  }
  
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleUpdateSnapshot()
    }
  }

  return (
    <TextInput className={styles.titleInput} autoFocus size={title.length} onKeyPress={handleKeyPress} value={title} onChange={val => setTitle(val)} onBlur={handleUpdateSnapshot}/>
  )
}
export const SnapshotItem = ({providerLead, snapshot, setOn, setDisableSave}: Props) => {
  const [editSnapshot, setEditSnapshot] = useState(false)
  const onIconClick = () => {
    setEditSnapshot(!editSnapshot)
  }

  let description = `${formatDate(snapshot.createdAt)} at ${formatTime(snapshot.createdAt)}`
  if (snapshot?.performedBy) {
    description = `${description} by ${snapshot.performedBy.name}`
  }

  useEffect(() => {
    if (setDisableSave) {
      setDisableSave(editSnapshot)
    }
  }, [editSnapshot, setDisableSave])

  return (
    <div key={snapshot.id} className={editSnapshot ? styles.editSnapshotItem : styles.snapshotItem}>
      <div className={styles.snapshotItemText}>
        <div className={styles.snapshotItemControl}>
          { editSnapshot ? <EditTitle snapshot={snapshot} setEditSnapshot={setEditSnapshot}/> : <TitleDisplay providerLead={providerLead} snapshot={snapshot} setOn={setOn} />}
          <Button noBorder as="icon" onClick={onIconClick}>
            <Icon.Pencil className={styles.editIcon}/>
          </Button>
        </div>
        <div className={styles.snapshotDescription}>
          {description}
        </div>
      </div>
    </div>
  )
}