import { removeAttributesWithNullValues } from 'utils'

const providerLeadFields = [
  'id',
  'created_at',
  'lead',
  'provider_lead_expenses',
  'providable',
  'economic_model_snapshots'
].join(',')

const providerLeadIncludes = [
  'lead',
  'lead.lead_users',
  'lead.lead_users.user',
  'provider_lead_expenses',
  'providable',
  'economic_model_snapshots'
].join(',')

const leadFields = ['created_at', 'full_address', 'name', 'partner_name', 'lead_users'].join(',')

const providerLeadExpensesFields = [
  'type',
  'slug',
  'category',
  'description',
  'display_name',
  'value'
].join(',')
const economicModelSnapshotsFields = ['payload', 'id', 'description', 'created_at'].join(',')

const tradeInLeadFields = [
  'created_at',
  'departing_property_expected_dom',
  'departing_property_external_loan_payoff',
  'departing_property_guaranteed_price',
  'departing_property_listing_prep_fee',
  'departing_property_ownership_expenses',
  'departing_property_repair_costs',
  'departing_property_solar_loan_balance',
  'departing_property_transaction_costs',
  'departing_property_valuation',
  'departing_property_valuation',
  'dp_actual_loan_payoff',
  'dp_buyers_agent_commission_rate',
  'dp_estimated_sale_price',
  'dp_first_position_payoffs',
  'dp_gp_percentage_of_valuation',
  'dp_heloc_balance',
  'dp_loan_to_value_ratio',
  'dp_local_transfer_tax_rate',
  'dp_local_transfer_tax_split_rate',
  'dp_max_downpayment_equity',
  'dp_other_liens_balance',
  'dp_non_first_position_balances',
  'dp_second_mortgage_balance',
  'dp_sellers_agent_commission_rate',
  'dp_solar_lease_payoff',
  'dp_target_annual_property_tax_rate',
  'dp_target_client_closing_costs',
  'dp_target_client_closing_costs_rate',
  'dp_target_monthly_hoa_fees',
  'dp_target_monthly_landscaping',
  'dp_target_monthly_property_insurance',
  'dp_target_monthly_utilities',
  'dp_target_monthly_water',
  'dp_target_new_home_purchase_equity',
  'dp_target_total_costs',
  'dp_target_trade_in_fee',
  'dp_target_trade_in_fee_rate',
  'dp_property_repair_holdback_rate',
  'dp_upfront_proceeds_to_client',
  'updated_at'
].join(',')

export const getSearchParams = () => {
  return removeAttributesWithNullValues({
    include: providerLeadIncludes,
    fields: {
      provider_lead: providerLeadFields,
      provider_lead_expense: providerLeadExpensesFields,
      trade_in_lead: tradeInLeadFields,
      economic_model_snapshot: economicModelSnapshotsFields,
      lead: leadFields,
      lead_user: ['role', 'user'].join(','),
      user: ['name', 'email'].join(',')
    }
  })
}
