import { postRequest } from 'utils'
import { getProviderLeadsUrl } from 'getUrl'

export const postProviderLeadSnapshot = ({ providerLeadId, payload }): Promise<any> => {
  return postRequest(
    getProviderLeadsUrl({
      id: providerLeadId,
      action: 'economic-model-snapshots'
    }),
    payload.payload
  )
}
