import { Button } from 'components'
import styles from './Sidebar.module.scss'
import { useFlags } from "flagsmith/react"
import { ReactComponent as Logo } from 'assets/logo.svg'
import { LegacySidebar } from "./LegacySidebar"
import { ProviderLead } from 'models'
import { ReactNode } from 'react'
import { useAuthContext } from "contexts"
import { BbysSidebar } from './BbysSidebar'

type Props = {
  providerLead: ProviderLead
  children: ReactNode
}
export const Sidebar = ({ providerLead, children }: Props) => {

  const { signOut } = useAuthContext();
  const {
    'updated-econ-model': { enabled: updatedEconModel, value },
  } = useFlags(['updated-econ-model']);
  const leadCreatedAt = providerLead && providerLead.createdAt
  const useNewModel = updatedEconModel && leadCreatedAt > value;

  return (
    <div className={useNewModel ? styles.bbysSidebar : styles.sidebar}>
      <Logo height="46.5" width="150" className={styles.logo} />
      <div className={styles.signout}>
        <Button as="link" onClick={signOut.mutateAsync}>
          Sign Out
        </Button>
      </div>
      {
        useNewModel ? <BbysSidebar providerLead={providerLead}/> : <LegacySidebar providerLead={providerLead} children={children} />
      }
    </div>
  )
}
