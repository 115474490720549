import { ReactNode } from 'react'
import { cx } from 'utils'
import styles from './Form.module.scss'

type Props = {
  children: ReactNode
  className?: string
  onSubmit: () => void
  title?: string
}

export const Form = ({ children, className, onSubmit, title, ...props }: Props) => {
  const handleSubmit = e => {
    onSubmit()
    e.preventDefault()
  }

  return (
    <form
      className={cx(styles.form, className)}
      onSubmit={handleSubmit}
      onKeyDown={e => e.stopPropagation()}
      {...props}
    >
      {title ? <h4 className={styles.title}>{title}</h4> : null}
      {children}
    </form>
  )
}
