import { Icon } from "components"
import { MarketSaleTab } from "./MarketSaleTab"
import { PropertyValueTab } from "./PropertyValueTab"
import styles from './BbysModelForm.module.scss'

import { useEconomicModelContext, useProviderLeadContext } from 'contexts'
import { useEffectOnce, useUpdateEffect, useExitWarning } from 'hooks'
import {
  ProviderLead,
  ProviderLeadExpense,
  TradeInProvidable
} from 'models'
import { formatDate, formatDisplayPercentValue, formatPercentValue } from "utils"
import { useEffect, useState } from "react"
import { PropertyAddress } from "./PropertyAddress"
import { MobileFooter } from "./MobileFooter"
import { useSidebar } from "../Sidebar/hooks"

type Props = {
  providerLead: ProviderLead
  mobileMenuOn: boolean
  setMobileMenuOn: (mobileMenuOn: boolean) => void
}

export const BbysModelForm = ({providerLead, mobileMenuOn, setMobileMenuOn}: Props) => {
  let snapshotTitle;
  const [activeTab, setActiveTab] = useState("propertyValue");

  const { economicModel, payload, set, setAll } = useEconomicModelContext()
  
  const {
    setCalculateExpense,
    calculateExpense,
    isCalculatingExpense,
    snapshotId,
    saveSnapshot,
    saveProviderLead,
    isSavingProviderLead,
    isUpdatingSnapshot
  } = useProviderLeadContext()

  const { address } = useSidebar(providerLead);
  const isLoading = isSavingProviderLead || isCalculatingExpense || isUpdatingSnapshot;

  const { setUnsavedForm } = useExitWarning();
  useEffectOnce(() => {
    setCalculateExpense(payload)
  })

  useUpdateEffect(() => {
    setCalculateExpense(economicModel.getPayload())
  }, [snapshotId])

  useUpdateEffect(() => {
    if (calculateExpense) {
      setAll(calculateExpense.toEconomicModel().getPayload())
    }
  }, [calculateExpense])

  useEffect(() => {
    if (isSavingProviderLead) {
      setUnsavedForm(false)
    }
  }, [isSavingProviderLead, setUnsavedForm])

  const handleOnBlur = () => {
    setUnsavedForm(true)
    setCalculateExpense(payload)
  }

  const handleChangeProvidable = (value: number, slug: keyof TradeInProvidable) => {
    set('providable', {
      ...payload.providable,
      [slug]: value
    })
  }

  const handleSaveSnapshot = async () => {
    await saveSnapshot({
      providerLeadId: providerLead.id,
      payload
    })
  }

  const handleSaveProviderLead = () => {
    handleSaveSnapshot()
    saveProviderLead({
      providerLeadId: providerLead.id,
      payload
    })
  }

  const isNegative = (value: number) => {
    return value && (value < 0)
  }

  const getProvidableInputProps = (slug: keyof TradeInProvidable) => {
    return {
      value: payload.providable[slug]?.toFixed(0).toString(),
      onChange: val => handleChangeProvidable(val, slug),
      onBlur: handleOnBlur,
      className: styles.moneyInput
    }
  }

  const getProvidableDisplayProps = (
    slug: keyof TradeInProvidable
  ): { value: string; displayType: 'text', className: string } => ({
    value: payload.providable[slug]?.toFixed(0).toString(),
    displayType: 'text',
    className: isNegative(payload.providable[slug]) ? styles.moneyDisplayNegative : styles.moneyDisplay
  })

  const handleChangeExpense = (value: number, expense: ProviderLeadExpense) => {
    const expenses = [...payload.expenses].filter(exp => exp.id !== expense.id)
    expenses.push({ ...expense, value })
    set('expenses', expenses)
  }

  const getProvidablePercentInputProps = (slug: keyof TradeInProvidable) => ({
    value: formatDisplayPercentValue(payload.providable[slug]).toString(),
    onChange: val => handleChangeProvidable(formatPercentValue(val), slug),
    onBlur: handleOnBlur,
    className: styles.percentInputs
  })

  const getProvidablePercentDisplayProps = (
    slug: keyof TradeInProvidable
  ): { value: string; displayType: 'text', className: string } => ({
    value: formatDisplayPercentValue(payload.providable[slug]).toString(),
    displayType: 'text',
    className: styles.percentInputs
  })

  const handleTabClick = (e)  => {
    setActiveTab(e.target.id)
  }

  if (snapshotId) {
    const snapshot = snapshotId && providerLead.getSnapshot(snapshotId)
    snapshotTitle = snapshot?.description ? snapshot.description : formatDate(snapshot.createdAt);
  }
  
  return (
    <div className={isLoading ? styles.loading : null}>
      <PropertyAddress className={styles.mobilePropertyAddress} address={address}></PropertyAddress>
      <div className={styles.heading}>
        <Icon.TradeInHouse className={styles.bbysIcon} viewBox="0 0 28 28"></Icon.TradeInHouse>
        <h1 className={styles.headingText}>{snapshotTitle || 'Buy Before You Sell Summary'}</h1>
      </div>
      <div className={styles.tabNavigation}>
        <h2 onClick={handleTabClick} id="propertyValue" className={activeTab === "propertyValue" ? styles.activeTab : null}>Approval Numbers</h2>
        <h2 onClick={handleTabClick} id="marketSale" className={activeTab === "marketSale" ? styles.activeTab : null}>Market Sale Scenario</h2>
      </div>
      <hr className={styles.tabNavBottom}/>
      <div className={styles.bbysPageBody}>
        <PropertyValueTab
          handleOnBlur={handleOnBlur}
          className={activeTab === "propertyValue" ? styles.activeTab : styles.hiddenTab}
          getProvidablePercentDisplayProps={getProvidablePercentDisplayProps}
          getProvidableInputProps={getProvidableInputProps}
          getProvidableDisplayProps={getProvidableDisplayProps}
          getProvidablePercentInputProps={getProvidablePercentInputProps}
          handleChangeExpense={handleChangeExpense}
        ></PropertyValueTab>
        <MarketSaleTab
          handleOnBlur={handleOnBlur}
          className={activeTab === "marketSale" ? styles.activeTab : styles.hiddenTab}
          getProvidableDisplayProps={getProvidableDisplayProps}
          getProvidablePercentInputProps={getProvidablePercentInputProps}
          handleChangeExpense={handleChangeExpense}
        ></MarketSaleTab>
      </div>
      <MobileFooter
        onClick={handleSaveProviderLead}
        isSavingProviderLead={isSavingProviderLead}
        providerLead={providerLead}
        on={mobileMenuOn}
        setOn={setMobileMenuOn}
      ></MobileFooter>
    </div>
  )
}
