import { useLocalStorage, useMutation, useQueryClient } from 'hooks'
import { useEffect } from 'react'
import { postSignin, postSignout } from 'services/Auth'
import { TOKEN } from '@types'

export const useAuth = () => {
  const queryCache = useQueryClient()
  const [token, setToken, clearToken] = useLocalStorage(TOKEN)

  useEffect(() => {
    if (!token) {
      queryCache.invalidateQueries()
    }
  }, [token, queryCache])

  const signIn = useMutation(postSignin, {
    onSuccess: token => {
      setToken(token)
    }
  })

  const signOut = useMutation(postSignout, { onSuccess: clearToken })

  return {
    signIn,
    signOut,
    token: token?.toString(),
    clearToken
  }
}
