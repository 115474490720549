import {
  Row,
  Table,
  MoneyMaskedInput as Money,
  PercentMaskedInput as Percent
} from 'components'
import styles from './BbysModelForm.module.scss'
import { TotalSellingCosts } from './TotalSellingCosts'
import { useEconomicModelContext, useProviderLeadContext } from 'contexts'
import { Button } from 'components/Button'

export const PropertyValueTab = ({
  className,
  handleChangeExpense,
  handleOnBlur,
  getProvidablePercentDisplayProps,
  getProvidableDisplayProps,
  getProvidableInputProps,
  getProvidablePercentInputProps
}) => {
  const { triggerGoalSeek, isCalculatingExpense } = useProviderLeadContext()
  const { payload } = useEconomicModelContext()

  return (
    <div className={className}>
      <Table>
        <h3>Approval Summary:</h3>
        <Row label="HomeLight home valuation" htmlFor="homeLightHomeValuation" labelClassName={styles.label}>
          <div className={styles.cell}>
            <Money
              {...getProvidableInputProps('departing_property_valuation')}
              data-testid="homeLightHomeValuation"
              id="homeLightHomeValuation"
            />
            <div className={styles.emptyVal}></div>
          </div>
        </Row>
        <Row label="Risk adjustment" htmlFor="riskAdjustment" labelClassName={styles.label}>
          <div className={styles.cell}>
            <Percent
              {...getProvidablePercentInputProps('dp_gp_percentage_of_valuation')}
              id="riskAdjustment"
            />
            <Money
              {...getProvidableDisplayProps('departing_property_guaranteed_price')}
              data-testid="riskAdjustmentVal"
            />
          </div>
        </Row>
        <hr />
        <TotalSellingCosts
          handleOnBlur={handleOnBlur}
          getProvidableDisplayProps={getProvidableDisplayProps}
          getProvidablePercentInputProps={getProvidablePercentInputProps}
          handleChangeExpense={handleChangeExpense}
        />
        <hr />
        <Row
          label="First Position Payoffs:"
          className={styles.totalRow}
          labelClassName={styles.label}
          htmlFor="bbysFee"
        >
          <div className={styles.cell}>
            <div className={styles.emptyVal}>-</div>
            <Money
              {...getProvidableDisplayProps('dp_first_position_payoffs')}
              labelClassName={styles.label}
              data-testid="firstPositionPayoffs"
            />
          </div>
        </Row>
        <Row
          label="First position lien balance"
          labelClassName={styles.label}
          htmlFor="firstPositionLienBalance"
        >
          <div className={styles.cell}>
            <div className={styles.emptyVal}>-</div>
            <Money
              {...getProvidableInputProps('departing_property_external_loan_payoff')}
              data-testid="firstPositionLienBalance"
              id="firstPositionLienBalance"
            />
          </div>
        </Row>
        <Row
          label="Solar lease payoff"
          labelClassName={styles.label}
          htmlFor="solarLeasePayoff"
        >
          <div className={styles.cell}>
            <div className={styles.emptyVal}>-</div>
            <Money
              {...getProvidableInputProps('dp_solar_lease_payoff')}
              data-testid="solarLeasePayoff"
              id="solarLeasePayoff"
            />
          </div>
        </Row>
        <hr />
        <Row
          label="Total Equity Unlock Amount"
          className={styles.highlitedVal}
          labelClassName={styles.label}
          htmlFor="estimatedEquityForNewHomePurchase"
        >
          <div className={styles.cell}>
            <div className={styles.emptyVal}>-</div>
            <Money
              {...getProvidableDisplayProps('dp_target_new_home_purchase_equity')}
              labelClassName={styles.label}
              allowNegative
              data-testid="estimatedEquityForNewHomePurchase"
            />
          </div>
        </Row>
        <Row
          label="Loan Payoff Value"
          className={styles.highlitedVal}
          labelClassName={styles.label}
          htmlFor="loanPayoffValue"
        >
          <div className={styles.cell}>
            <div className={styles.emptyVal}>-</div>
            <Money
              {...getProvidableDisplayProps('loan_payoff_value')}
              labelClassName={styles.label}
              allowNegative
              data-testid="loanPayoffValue"
            />
          </div>
        </Row>
        <Row
          label="Combined loan to value ratio"
          labelClassName={styles.label}
          htmlFor="loanToValueRatio"
        >
          <div className={styles.cell}>
            <div className={styles.emptyVal}>-</div>
            <Percent
              {...getProvidablePercentDisplayProps('dp_loan_to_value_ratio')}
              className={payload.providable['dp_loan_to_value_ratio'] > 0.80 ? styles.ratioRed : styles.ratio }
              data-testid="loanToValueRatio"
            />
          </div>
        </Row>
        <hr />
        <Row
          label="Non 1st Position Balances:"
          className={styles.totalRow}
          labelClassName={styles.label}
          htmlFor="nonFirstPositionBalances"
        >
          <div className={styles.cell}>
            <div className={styles.emptyVal}>-</div>
            <Money
              {...getProvidableDisplayProps('dp_non_first_position_balances')}
              labelClassName={styles.label}
              data-testid="nonFirstPositionBalances"
              id="nonFirstPositionBalances"
            />
          </div>
        </Row>
        <Row
          label="Second mortgage balance"
          labelClassName={styles.label}
          htmlFor="secondMortgageBalance"
        >
          <div className={styles.cell}>
            <div className={styles.emptyVal}>-</div>
            <Money
              {...getProvidableInputProps('dp_second_mortgage_balance')}
              data-testid="secondMortgageBalance"
              id="secondMortgageBalance"
            />
          </div>
        </Row>
        <Row
          label="HELOC balance"
          labelClassName={styles.label}
          htmlFor="helocBalance"
        >
          <div className={styles.cell}>
            <div className={styles.emptyVal}>-</div>
            <Money
              {...getProvidableInputProps('dp_heloc_balance')}
              data-testid="helocBalance"
              id="helocBalance"
            />
          </div>
        </Row>
        <Row
          label="Solar loan balance"
          labelClassName={styles.label}
          htmlFor="solarLoanBalance"
        >
          <div className={styles.cell}>
            <div className={styles.emptyVal}>-</div>
            <Money
              {...getProvidableInputProps('departing_property_solar_loan_balance')}
              data-testid="solarLoanBalance"
              id="solarLoanBalance"
            />
          </div>
        </Row>
        <Row
          label="Other liens"
          labelClassName={styles.label}
          htmlFor="otherLiens"
        >
          <div className={styles.cell}>
            <div className={styles.emptyVal}>-</div>
            <Money
              {...getProvidableInputProps('dp_other_liens_balance')}
              data-testid="otherLiens"
              id="otherLiens"
            />
          </div>
        </Row>
        <hr />
        <Row
          label="Maximum equity to be used for a down payment"
          className={styles.finalVal}
          labelClassName={styles.label}
          htmlFor="estimatedEquityForNewHomePurchase"
        >
          <div className={styles.cell}>
            <div className={styles.emptyVal}></div>
            <Money
              {...getProvidableDisplayProps('dp_max_downpayment_equity')}
              labelClassName={styles.label}
              allowNegative
              data-testid="maxDownpaymentEquity"
            />
          </div>
        </Row>
        <Row
          label="Goal Seek"
          className={styles.finalVal}
          labelClassName={styles.label}
          htmlFor="goalSeek"
        >
          <div className={styles.cell}>
            <div className={styles.emptyVal}></div>
            <Money
              {...getProvidableInputProps('dp_downpayment_equity_goal')}
              labelClassName={styles.label}
              id="goalSeek"
            />
            <Button
              className={styles.sideButton}
              disabled={isCalculatingExpense}
              submitting={isCalculatingExpense}
              as="secondary"
              text="Run"
              onClick={triggerGoalSeek}
            />
          </div>
        </Row>
      </Table>
    </div>
  )
}
