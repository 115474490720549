export const tradeInProvidableDescription = {
  departing_property_valuation: 'HomeLight home valuation',
  dp_gp_percentage_of_valuation: 'HomeLight purchase price rate',
  departing_property_guaranteed_price: 'HomeLight purchase price',
  departing_property_expected_dom: 'Expected days owned by HomeLight',
  dp_actual_loan_payoff: 'Loan Payoff Value',
  dp_buyers_agent_commission_rate: 'Buyer agent commission',
  dp_heloc_balance: 'HELOC balance',
  dp_loan_to_value_ratio: 'Combined loan to value ratio',
  dp_max_downpayment_equity: 'Maximum equity to be used for a down payment',
  dp_other_liens_balance: 'Other liens',
  dp_non_first_position_balances: 'Non 1st Position Balances',
  dp_sellers_agent_commission_rate: 'Seller agent commission',
  departing_property_ownership_expenses: 'Property ownership costs',
  departing_property_transaction_costs: 'Transaction costs',
  departing_property_repair_costs: 'Repair costs & valuation adjustments',
  departing_property_listing_prep_fee: 'Listing prep work',
  dp_solar_lease_payoff: 'Solar lease payoff',
  dp_target_trade_in_fee_rate: 'HomeLight Trade-In fee rate',
  dp_target_trade_in_fee: 'HomeLight Trade-In fee',
  dp_target_total_costs: 'Total selling costs',
  dp_upfront_proceeds_to_client: 'Estimated upfront proceeds',
  departing_property_external_loan_payoff: 'Estimated loan payoff',
  departing_property_solar_loan_balance: 'Solar loan balance',
  dp_target_client_closing_costs: 'Estimated closing costs',
  dp_target_new_home_purchase_equity: 'Estimated equity from new home purchase',
  dp_estimated_sale_price: 'Market sale price',
  dp_first_position_payoffs: 'First position payoffs',
  dp_second_mortgage_balance: 'Second mortgage balance',
  dp_total_agent_commission: 'Agent fees',
  dp_local_transfer_tax_rate: 'City transfer taxes',
  dp_local_transfer_tax_split_rate: 'Split Transfer Tax',
  dp_target_annual_property_tax_rate: 'Property taxes',
  dp_target_monthly_property_insurance: 'Property insurance',
  dp_target_monthly_hoa_fees: 'HOA fees (if applicable)',
  dp_target_monthly_water: 'Water',
  dp_target_monthly_utilities: 'PG&E utilities',
  dp_target_monthly_landscaping: 'Landscaping',
  dp_target_client_closing_costs_rate: 'Estimated closing costs %',
  target_property_pest_inspections: 'Inspection cost'
}

export const tradeInProvidableRates = [
  'dp_gp_percentage_of_valuation',
  'dp_buyers_agent_commission_rate',
  'dp_sellers_agent_commission_rate',
  'dp_target_trade_in_fee_rate',
  'dp_target_annual_property_tax_rate',
  'dp_target_client_closing_costs_rate'
]

export const propertyOwnershipCostsProvidableMapping = {
  target_property_taxes: 'dp_target_annual_property_tax_rate',
  target_property_insurance: 'dp_target_monthly_property_insurance',
  target_hoa_fees: 'dp_target_monthly_hoa_fees',
  target_water: 'dp_target_monthly_water',
  target_utilities: 'dp_target_monthly_utilities',
  target_landscaping: 'dp_target_monthly_landscaping'
}
