import { useState } from 'react'
import { ActionBar, Button, Popup, Form, FormField, TextInput, FormActions } from 'components'

type Props = {
  initialName?: string
  isLoading?: boolean
  onCancel: () => void
  onSubmit: (val: string) => void
}

export const SnapshotEditForm = ({ initialName = '', onCancel, onSubmit, isLoading }: Props) => {
  const [name, setName] = useState(initialName)

  const handleOnSubmit = async () => {
    await onSubmit(name)
    onCancel()
  }

  return (
    <Popup title="Edit Snapshot Name" onCancel={onCancel}>
      <Form onSubmit={handleOnSubmit}>
        <FormField title="Name" required>
          <TextInput
            onChange={val => setName(val)}
            name="name"
            autoComplete="off"
            value={name}
            data-testid="name-field"
          />
        </FormField>
        <FormActions>
          <ActionBar>
            <Button as="cancel" onClick={onCancel} size="medium">
              Cancel
            </Button>
            <Button as="submit" size="medium" data-testid="submit-button" disabled={isLoading}>
              {isLoading ? 'Submitting...' : 'Submit'}
            </Button>
          </ActionBar>
        </FormActions>
      </Form>
    </Popup>
  )
}
