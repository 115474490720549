import { Icon } from 'components'
import styles from './BbysModelForm.module.scss'

type Props = {
  className?: string
  address?: {
    city?: string;
    state?: string;
    cityState?: string;
    street?: string;
  }
}

export const PropertyAddress = ({className, address}: Props) => {
  return (
    <div className={className}>
      { address && (
        <div>
          <p>Property Address</p>
          <div className={styles.addressPart}>{address.street}</div>
          <div className={styles.addressPart}>{address.cityState}</div>
        </div>
      )}
      <div className={styles.homeLightHomeLoans}>
        <a href="https://homelighthomeloans.com/mortgage-rates/" target="_blank" rel="noreferrer">
          <span>Mortgage Rates</span>
          <Icon.ExternalLink />
        </a>
      </div>
      <hr></hr>
    </div>
  )
}
