import { ProviderLead } from 'models'
import { structureAddress } from 'utils'

export const useSidebar = (providerLead: ProviderLead) => {
  const address = structureAddress(providerLead.lead.fullAddress)

  const loanOfficer = providerLead.lead.loanOfficer ? providerLead.lead.loanOfficer : undefined
  const clientManager = providerLead.lead.clientManager
    ? providerLead.lead.clientManager
    : undefined

  return {
    address: providerLead.lead.fullAddress ? address : undefined,
    loanOfficer,
    clientManager
  }
}
