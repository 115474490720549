import { Icon } from 'components/Icon'
import { ReactNode } from 'react'
import { cx } from 'utils'
import styles from './Table.module.scss'

type Props = {
  on?: boolean
  title: ReactNode
  toggle?: () => void
  className?: string
}

export const Label = ({ on, title, toggle, className }: Props) => (
  <div onClick={toggle} className={cx(styles.summaryLabel, className)}>
    {on ? (
      <Icon.DownArrow data-testid="downArrow" />
    ) : (
      <Icon.DownArrow data-testid="rightArrow" className={styles.rotateRight} />
    )}
    {title}
  </div>
)
