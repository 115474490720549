import { Collapsible } from 'components/Table/Collapsible'
import styles from './SnapshotHistory.module.scss'
import { Button, ToggleState } from 'components'
import { SnapshotItem } from './SnapshotItem'
import { useState } from 'react'
import { ProviderLead } from 'models'

type Props = {
  onClick: () => void
  providerLead: ProviderLead
  isSavingProviderLead: boolean
  on: boolean
  setOn?: (on: boolean) => void
}

export const MobileFooter = ({onClick, isSavingProviderLead, providerLead, on, setOn}: Props) => {
  const onHistoryClick = () => setOn(!on);
  const [disableSave, setDisableSave] = useState(false)

  const numSnapshots = providerLead?.economicModelSnapshots?.length;
  return (
    <footer className={styles.mobileFooter}>
      <ToggleState on={on} setOn={setOn}>
        <ToggleState.Show>
          <div className={styles.historyBodyMobile}>
            <h3 className={styles.historyHeader}>History</h3>
            <div className={styles.snapshotsList}>
              {providerLead?.economicModelSnapshots
                .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                .map(snapshot => {
                  return <SnapshotItem providerLead={providerLead} snapshot={snapshot} setOn={setOn} setDisableSave={setDisableSave}/>
                })}
            </div>
          </div>
        </ToggleState.Show>
        <div className={styles.footerMenu}>
          <div className={styles.historyDropdownMobile} onClick={onHistoryClick}>
            <div>{`History (${numSnapshots})`}</div>
            <Collapsible on={!on} rowClass={styles.historyToggle}></Collapsible>
          </div>
          <Button
            as="primary"
            isBlock
            onClick={onClick}
            className={disableSave ? styles.footerButtonDisabled : styles.footerButton}
          >
            {isSavingProviderLead ? 'Saving' : 'Save to Database'}
          </Button>
        </div>
      </ToggleState>
    </footer>
  )
}