import { createContext, useContext, useState } from 'react'

export interface SidebarContextType {
  on: boolean
  setOn: (on: boolean) => void
}

const SidebarContext = createContext<SidebarContextType | null>(null)
export const useSidebarContext = () => {
  const context = useContext(SidebarContext)
  if (!context) {
    throw new Error('useSidebarContext must be called within a SidebarProvider')
  }
  return context
}

export const SidebarProvider = ({ children }) => {
  const [on, setOn] = useState(false)

  return <SidebarContext.Provider value={{ on, setOn }}>{children}</SidebarContext.Provider>
}
