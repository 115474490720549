import { RouteComponentProps, Router } from '@reach/router'
import { DetailsPage } from 'pages/DetailsPage'

export const NotFound = (props: RouteComponentProps) => {
  return <p>Error 404 - Page Not Found</p>
}

export const Routes = () => {
  return (
    <Router primary={false}>
      <NotFound default />
      <DetailsPage path="/details/:providerLeadId" />
      <DetailsPage path="/details/:providerLeadId/snapshot/:snapshotId" />
    </Router>
  )
}
