import { ProviderLeadExpenseResponse } from '@types'

export type ProviderLeadExpenseSlug =
  | 'target_total_agent_commission'
  | 'target_hoa_fees'
  | 'target_landscaping'
  | 'target_property_insurance'
  | 'target_property_taxes'
  | 'target_utilities'
  | 'target_water'
  | 'target_total_city_transfer_tax'
  | 'target_cosmetic_updates'
  | 'target_deep_cleaning'
  | 'target_miscellaneous_costs'
  | 'target_property_staging'
  | 'target_property_pest_inspections'
  | 'target_property_repairs'
  | 'target_property_valuation_adjustment'
  | 'target_home_warranty_policy'
  | 'homelight_purchase_price'
  | 'total_listing_preparation_expenses'
  | 'total_ownership_expenses'
  | 'total_repair_costs_valuation_adjustments'
  | 'target_new_home_purchase_equity'
  | 'target_trade_in_fee'
  | 'total_transaction_expenses'
  | 'upfront_proceeds_to_client'
  | 'target_monthly_hoa_fees'
  | 'target_monthly_landscaping'
  | 'target_monthly_property_insurance'
  | 'target_monthly_utilities'
  | 'target_monthly_water'
  | 'target_city_transfer_tax'
  | 'other'
  | 'target_property_repair_holdback_cost'
  | 'target_valuation_adjustment'

export type ProviderLeadExpenseCategory =
  | 'agent_commission'
  | 'ownership_expenses'
  | 'transaction_expenses'
  | 'listing_preparation_expenses'
  | 'repair_costs_valuation_adjustments'
  | 'transaction_expenses'
  | 'Agent Commission'
  | 'Transaction Expenses'
  | 'Ownership Expenses'
  | 'Repair Costs & Valuation Adjustments'
  | 'Listing Preparation Expenses'
  | null

export class ProviderLeadExpense {
  slug: ProviderLeadExpenseSlug
  category: ProviderLeadExpenseCategory
  description: string
  displayName: string
  value: number
  id: string
  constructor(providerLeadExpense: ProviderLeadExpenseResponse) {
    Object.assign(this, providerLeadExpense)
    this.value = parseFloat(providerLeadExpense.value)
  }
}
