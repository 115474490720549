import { createContext, ReactNode, useContext } from 'react'
import { useProviderLead } from './useProviderLead'
import { ProviderLead } from 'models'

export interface ProviderLeadContextType {
  providerLead: ProviderLead | undefined
  calculateExpense: ProviderLead | undefined
  marketSalePrice: ProviderLead | undefined
  isLoading: boolean
  saveSnapshot: (payload: any) => void
  setCalculateExpense: (payload: any) => void
  saveProviderLead: (payload: any) => void
  updateSnapshot: (payload: any) => void
  removeSnapshot: (id: any) => void
  triggerGoalSeek: () => void
  snapshotId?: string
  isSavingSnapshot: boolean
  isSavingProviderLead: boolean
  isCalculatingExpense: boolean
  isUpdatingSnapshot: boolean
  isRemovingSnapshot: boolean
  isLoadingMarketSalePrice: boolean
}

const ProviderLeadContext = createContext<ProviderLeadContextType | null>(null)
export const useProviderLeadContext = () => {
  const context = useContext(ProviderLeadContext)
  if (!context) {
    throw new Error('useProviderLeadContext must be called within a ProviderLeadProvider')
  }
  return context
}

type Props = {
  children: ReactNode
  providerLeadId: string
  snapshotId?: string
}

export const ProviderLeadProvider = ({ children, providerLeadId, snapshotId }: Props) => {
  return (
    <ProviderLeadContext.Provider value={useProviderLead(providerLeadId, snapshotId)}>
      {children}
    </ProviderLeadContext.Provider>
  )
}
