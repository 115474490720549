import { Caret } from './Caret'
import { ReactNode } from 'react'

type Props = {
  toggle?: () => void
  on?: boolean
  title?: ReactNode
  children?: ReactNode
  rowClass?: string
  labelClass?: string
}

export const Collapsible = ({ toggle, on, title, children, rowClass, labelClass }: Props) => (
  <tr className={rowClass}>
    <td className={labelClass}>
      <Caret data-testid='caret' on={on} title={title} toggle={toggle} />
    </td>
    {children}
  </tr>
)
