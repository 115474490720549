import axios from 'axios'
import { TOKEN } from '@types'
import { deleteFromStorage } from '@homelight/react-hook-uselocalstorage'

export const makeRequest = axios.create()

makeRequest.interceptors.request.use(config => {
  const token = window.localStorage.getItem(TOKEN)
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

makeRequest.interceptors.response.use(undefined, error => {
  if (error.response?.status === 401) {
    deleteFromStorage(TOKEN)
  }
  return Promise.reject(error)
})

export const getRequest = (
  url: string,
  { params, data }: { params?: object; data?: object } = {}
) => makeRequest({ url, params, data })

export const postRequest = (url: string, data = {}) => makeRequest({ url, data, method: 'post' })

export const getBodyRequest = (url: string, data = {}, params = {}) =>
  makeRequest({ url, data, params, method: 'GET' })

export const putRequest = (url: string, data = {}) => makeRequest({ url, data, method: 'put' })

export const deleteRequest = (url: string) => makeRequest({ url, method: 'delete' })

export const sourceRequest = axios.CancelToken.source
