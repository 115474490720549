import {
  Row,
  Table,
  Toggle,
  MoneyMaskedInput as Money,
  PercentMaskedInput as Percent
} from 'components'
import styles from './BbysModelForm.module.scss'
import {
  ProviderLeadExpense,
  ProviderLeadExpenseCategory,
  ProviderLeadExpenseSlug,
} from 'models'
import {
  dictionary,
  formatDisplayPercentValue,
  valueOrZero
} from 'utils'

import { Collapsible } from 'components/Table/Collapsible'
import { useEconomicModelContext } from 'contexts'

export const TotalMarketSellingCosts = ({
    totalCosts,
    agentFees,
    bbysFee,
    closingCosts,
    getProvidablePercentInputProps,
    handleOnBlur,
    handleChangeExpense
  }) => {

    const getProvidableDisplayProps = (
      value: number
    ): { value: string; displayType: 'text', className: string } => ({
      value: value?.toFixed(0).toString(),
      displayType: 'text',
      className: styles.moneyDisplay
    })
  const { economicModel, payload } = useEconomicModelContext()
  const { getExpensesByCategory } = economicModel
  const getAgentFeesRate = () => {
    return formatDisplayPercentValue(
      valueOrZero(payload.providable.dp_buyers_agent_commission_rate) +
        valueOrZero(payload.providable.dp_sellers_agent_commission_rate)
    )
  }

  const getExpenseBySlug = (slug: ProviderLeadExpenseSlug) =>
    payload.expenses.find(expense => expense.slug === slug)

  const getExpenseById = (id: string) => payload.expenses.find(expense => expense.id === id)

  const getExpenseInputProps = (expense: ProviderLeadExpense) => ({
    value: getExpenseById(expense.id)?.value?.toFixed(0).toString(),
    onChange: val => handleChangeExpense(val, expense),
    onBlur: handleOnBlur,
    className: styles.moneyInput
  })

  const getExpenseLabelBySlug = (slug: ProviderLeadExpenseSlug) => {
    const expense = getExpenseBySlug(slug)
    return dictionary(slug) === slug ? expense.displayName : dictionary(slug)
  }

  const getExpenseInputPropsBySlug = (
    slug: ProviderLeadExpenseSlug,
    category: ProviderLeadExpenseCategory
  ) => {
    const expense = getExpensesByCategory(category).find(expense => expense.slug === slug)
    return expense && getExpenseInputProps(expense)
  }

  const getAgentCommissionPartial = (slug: string) => {
    const totalAgentFeeRate = payload.providable.dp_buyers_agent_commission_rate + payload.providable.dp_sellers_agent_commission_rate;
    const percentOfTotal = payload.providable[slug] / totalAgentFeeRate;
    const value = Math.round((percentOfTotal * getExpenseBySlug('target_total_agent_commission')?.value) / 100) * 100
    return (
      valueOrZero(value)
    )
  }

  // Calculations for market sale price should not alter the trade in lead model and
  // should just be made on top of the numbers returned from the backend
  const getRepairCostsValuationAdjustments = (slug: ProviderLeadExpenseSlug) =>
    getExpenseInputPropsBySlug(slug, 'repair_costs_valuation_adjustments')

  return (
    <Table>
      <Row
        label="Total Selling Costs:"
        className={styles.totalRow}
        labelClassName={styles.label}
        htmlFor="totalSellingCosts"
      >
        <div className={styles.cell}>
          <div className={styles.emptyVal}>-</div>
          <Money
            {...getProvidableDisplayProps(totalCosts)}
            labelClassName={styles.label}
            data-testid="totalSellingCosts"
          />
        </div>
      </Row>
      <Toggle>
        <Collapsible rowClass={styles.collapsible} labelClass={styles.label} title='Agent fees'>
          <div className={styles.cell}>
            <Percent
              value={getAgentFeesRate().toString()}
              displayType="text"
              className={styles.percentDisplay}
              data-testid="agentFeesCalculatedRate"
            />
            <Money
              {...getProvidableDisplayProps(agentFees)}
            />
          </div>
        </Collapsible>
        <Toggle.Show>
          <Row
            className={styles.subcategory}
            labelClassName={styles.label}
            label="- Buyer agent commission"
            htmlFor="-buyerAgentCommission"
          >
            <div className={styles.cell}>
              <Percent
                {...getProvidablePercentInputProps('dp_buyers_agent_commission_rate')}
                id="-buyerAgentCommission"
              />
              <Money
                value={getAgentCommissionPartial('dp_buyers_agent_commission_rate')}
                displayType='text'
                className={styles.moneyDisplay}
              />
            </div>
          </Row>
          <Row
            className={styles.subcategory}
            labelClassName={styles.label}
            label="- Seller agent commission"
            htmlFor="sellerAgentCommission"
          >
            <div className={styles.cell}>
              <Percent
                {...getProvidablePercentInputProps('dp_sellers_agent_commission_rate')}
                id="sellerAgentCommission"
              />
              <Money
                value={getAgentCommissionPartial('dp_sellers_agent_commission_rate')}
                displayType='text'
                className={styles.moneyDisplay}
              />
            </div>
          </Row>
        </Toggle.Show>
      </Toggle>
      {getExpenseBySlug('target_property_pest_inspections') && (
        <Row
          labelClassName={styles.label}
          label={getExpenseLabelBySlug('target_property_pest_inspections')}
          htmlFor="inspectionCost"
        >
          <div className={styles.cell}>
            <div className={styles.emptyVal}>-</div>
            <Money
              {...getRepairCostsValuationAdjustments('target_property_pest_inspections')}
              id="inspectionCost"
              data-testid="inspectionCost"
            />
          </div>
        </Row>
      )}
      <Row
        label="Buy Before You Sell fee"
        labelClassName={styles.label}
        htmlFor="bbysFee"
      >
        <div className={styles.cell}>
          <Percent
            {...getProvidablePercentInputProps('dp_target_trade_in_fee_rate')}
            allowEmptyFormatting
            id="bbysFee"
          />
          <Money
            {...getProvidableDisplayProps(bbysFee)}
          />
        </div>
      </Row>
      <Row
        label="Closing costs"
        labelClassName={styles.label}
        htmlFor="closingCosts"
      >
        <div className={styles.cell}>
          <Percent
            {...getProvidablePercentInputProps('dp_target_client_closing_costs_rate')}
            allowEmptyFormatting
            data-testid="closingCostsRate"
            id="closingCosts"
          />
          <Money
            {...getProvidableDisplayProps(closingCosts)}
          />
        </div>

      </Row>
      <Row
        label="MISC costs"
        labelClassName={styles.label}
        htmlFor="mISCCosts"
      >
        <div className={styles.cell}>
          <div className={styles.emptyVal}>-</div>
          <Money
            {...getRepairCostsValuationAdjustments('target_miscellaneous_costs')}
            data-testid="mISCCosts"
            id="mISCCosts"
          />
        </div>
      </Row>
      </Table>
  )
}
