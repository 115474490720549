import PropTypes from 'prop-types'
import React from 'react'
import { Icon } from 'components/Icon'
import styles from './Popup.module.scss'

export const PopupHeader = ({ title, onCancel, actions }) => {
  if (!title) {
    return null
  }
  return (
    <div className={styles.header}>
      {title ? <div className={styles.title}>{title}</div> : null}
      {actions}
      {onCancel ? (
        <button
          type="button"
          className={styles.close}
          onClick={onCancel}
          data-testid="popup-cancel-button"
        >
          <Icon.Close />
        </button>
      ) : null}
    </div>
  )
}

PopupHeader.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.node,
  onCancel: PropTypes.func
}

PopupHeader.defaultProps = {
  title: undefined,
  actions: undefined,
  onCancel: undefined
}
