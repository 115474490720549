import { useState, Children, cloneElement, ReactElement, ReactNode } from 'react'

export const Toggle = ({ children }) => {
  const [on, setOn] = useState(false)

  const toggle = () => setOn(on => !on)

  return Children.map(children, child =>
    cloneElement(child, {
      toggle,
      on
    })
  )
}

Toggle.Show = ({ on, children }: { on?: boolean; children: ReactNode }) =>
  on ? (children as ReactElement<any>) : null
