import {
  Final,
  Row,
  Separator,
  Section,
  Table,
  Total,
  Summary,
  Toggle,
  MaskedInput,
  MoneyMaskedInput as Money,
  PercentMaskedInput as Percent,
  SelectInput
} from 'components'
import styles from './EconomicModelForm.module.scss'
import { useEconomicModelContext, useProviderLeadContext } from 'contexts'
import { useEffectOnce, useUpdateEffect } from 'hooks'
import {
  ProviderLeadExpense,
  ProviderLeadExpenseCategory,
  ProviderLeadExpenseSlug,
  TradeInProvidable
} from 'models'
import {
  cx,
  dictionary,
  formatDisplayPercentValue,
  formatPercentValue,
  toCamelCase,
  valueOrZero
} from 'utils'
import { propertyOwnershipCostsProvidableMapping, tradeInProvidableRates } from '@constants'

const sortOtherLast = (a: { slug: 'other' | string }, b: { slug: 'other' | string }) =>
  a.slug === 'other' ? 1 : b.slug === 'other' ? -1 : 0

export const EconomicModelForm = ({ children }) => {
  const { economicModel, payload, set, setAll } = useEconomicModelContext()
  const {
    setCalculateExpense,
    calculateExpense,
    isCalculatingExpense,
    marketSalePrice,
    snapshotId
  } = useProviderLeadContext()
  const { getExpensesByCategory } = economicModel

  useEffectOnce(() => setCalculateExpense(payload))

  useUpdateEffect(() => {
    setCalculateExpense(economicModel.getPayload())
  }, [snapshotId])

  useUpdateEffect(() => {
    if (calculateExpense) {
      setAll(calculateExpense.toEconomicModel().getPayload())
    }
  }, [calculateExpense])

  const handleOnBlur = () => setCalculateExpense(payload)

  const getNetProceedsFromMarketSale = () => {
    const estimatedMarketSalePrice = valueOrZero(payload.providable.dp_estimated_sale_price)
    if (estimatedMarketSalePrice === 0) return 0

    const netProceedsFromMarketSale =
      estimatedMarketSalePrice -
      valueOrZero(getMarketSalePriceExpenseBySlug('target_total_agent_commission')?.value) -
      valueOrZero(payload.providable.departing_property_ownership_expenses) -
      valueOrZero(payload.providable.departing_property_transaction_costs) -
      valueOrZero(getMarketSalePriceProvidable('departing_property_repair_costs')) -
      valueOrZero(getMarketSalePriceProvidable('departing_property_listing_prep_fee')) -
      valueOrZero(getMarketSalePriceProvidable('dp_target_trade_in_fee'))

    return netProceedsFromMarketSale
  }

  const getAdditionalProceedsAfterMarketSale = () => {
    const estimatedMarketSalePrice = valueOrZero(payload.providable.dp_estimated_sale_price)
    if (estimatedMarketSalePrice === 0) return 0

    return getNetProceedsFromMarketSale() - payload.providable.dp_upfront_proceeds_to_client
  }

  const getEstimatedTotalEquityReceived = () => {
    const estimatedMarketSalePrice = valueOrZero(payload.providable.dp_estimated_sale_price)
    if (estimatedMarketSalePrice === 0) return 0

    return (
      payload.providable.dp_target_new_home_purchase_equity + getAdditionalProceedsAfterMarketSale()
    )
  }

  const getAgentFeesRate = () => {
    return formatDisplayPercentValue(
      valueOrZero(payload.providable.dp_buyers_agent_commission_rate) +
        valueOrZero(payload.providable.dp_sellers_agent_commission_rate)
    )
  }

  const getTotalClosingCostsRate = () => {
    return formatDisplayPercentValue(
      valueOrZero(payload.providable.dp_target_client_closing_costs_rate) +
        valueOrZero(
          payload.providable.dp_local_transfer_tax_rate *
            payload.providable.dp_local_transfer_tax_split_rate
        )
    )
  }

  const getExpenseBySlug = (slug: ProviderLeadExpenseSlug) =>
    payload.expenses.find(expense => expense.slug === slug)

  const getExpenseById = (id: string) => payload.expenses.find(expense => expense.id === id)

  const getMarketSalePriceExpenseBySlug = (slug: ProviderLeadExpenseSlug) =>
    marketSalePrice?.providerLeadExpenses.find(expense => expense.slug === slug)

  const getMarketSalePriceProvidable = (slug: keyof TradeInProvidable) =>
    marketSalePrice?.tradeInLead.getProvidable()[slug]

  const handleChangeProvidable = (value, slug: keyof TradeInProvidable) => {
    set('providable', {
      ...payload.providable,
      [slug]: value
    })
  }

  const handleChangeExpense = (value, expense: ProviderLeadExpense) => {
    const expenses = [...payload.expenses].filter(exp => exp.id !== expense.id)
    expenses.push({ ...expense, value })
    set('expenses', expenses)
  }

  const getProvidableInputProps = (slug: keyof TradeInProvidable) => {
    return {
      value: payload.providable[slug]?.toFixed(0).toString(),
      onChange: val => handleChangeProvidable(val, slug),
      onBlur: handleOnBlur
    }
  }

  const getProvidableNumberProps = (slug: keyof TradeInProvidable) => {
    return {
      value: payload.providable[slug]?.toString(),
      onChange: val => handleChangeProvidable(val, slug),
      onBlur: handleOnBlur
    }
  }

  const getProvidableDisplayProps = (
    slug: keyof TradeInProvidable
  ): { value: string; displayType: 'text' } => ({
    value: payload.providable[slug]?.toFixed(0).toString(),
    displayType: 'text'
  })

  const getExpenseDisplayProps = (
    slug: ProviderLeadExpenseSlug
  ): { value: string; displayType: 'text' } => ({
    value: getExpenseBySlug(slug)?.value.toFixed(0).toString(),
    displayType: 'text'
  })

  const getExpenseInputProps = (expense: ProviderLeadExpense) => ({
    value: getExpenseById(expense.id)?.value?.toFixed(0).toString(),
    onChange: val => handleChangeExpense(val, expense),
    onBlur: handleOnBlur,
    className: styles.smallInput
  })

  const getProvidablePercentInputProps = (slug: keyof TradeInProvidable) => ({
    value: formatDisplayPercentValue(payload.providable[slug]).toString(),
    onChange: val => handleChangeProvidable(formatPercentValue(val), slug),
    onBlur: handleOnBlur,
    className: styles.percentInputs
  })

  const getExpenseLabelBySlug = (slug: ProviderLeadExpenseSlug) => {
    const expense = getExpenseBySlug(slug)
    return dictionary(slug) === slug ? expense.displayName : dictionary(slug)
  }

  const getExpenseInputPropsBySlug = (
    slug: ProviderLeadExpenseSlug,
    category: ProviderLeadExpenseCategory
  ) => {
    const expense = getExpensesByCategory(category).find(expense => expense.slug === slug)
    return getExpenseInputProps(expense)
  }

  const getRepairCostsValuationAdjustments = (slug: ProviderLeadExpenseSlug) =>
    getExpenseInputPropsBySlug(slug, 'repair_costs_valuation_adjustments')

  return (
    <div className={cx(styles.economicModel, isCalculatingExpense ? styles.calculating : null)}>
      {children}
      <Table>
        <Row label="HomeLight home valuation" htmlFor="homeLightHomeValuation">
          <span />
          <Money
            {...getProvidableInputProps('departing_property_valuation')}
            id="homeLightHomeValuation"
          />
        </Row>
        <Row label="HomeLight purchase price" htmlFor="homeLightPurchasePrice">
          <Percent
            {...getProvidablePercentInputProps('dp_gp_percentage_of_valuation')}
            id="homeLightPurchasePrice"
          />
          <Money
            {...getProvidableDisplayProps('departing_property_guaranteed_price')}
            data-testid="homeLightPurchasePriceCalculated"
          />
        </Row>
        <Row label="Expected days owned by HomeLight" htmlFor="expectedDaysOwnedByHomeLight">
          <MaskedInput
            className={styles.smallInput}
            suffix={' days'}
            {...getProvidableInputProps('departing_property_expected_dom')}
            id="expectedDaysOwnedByHomeLight"
          />
          <span />
        </Row>
        <Section>
          <hr />
        </Section>
        <Section>When HomeLight buys your property from you:</Section>
        <Toggle>
          <Summary title={<strong>Total estimated costs</strong>}>
            <span />
            <Money
              {...getProvidableDisplayProps('dp_target_total_costs')}
              className={styles.total}
              data-testid="totalEstimatedCosts"
            />
          </Summary>
          <Toggle.Show>
            <Toggle>
              <Summary title="Agent fees" className={styles.spacingLeft}>
                <Percent
                  value={getAgentFeesRate().toString()}
                  displayType="text"
                  className={styles.removeAccent}
                  data-testid="agentFeesCalculatedRate"
                />
                <Money
                  {...getExpenseDisplayProps('target_total_agent_commission')}
                  data-testid="agentFeesCalculatedAmount"
                />
              </Summary>
              <Toggle.Show>
                <Row
                  className={styles.detailsRow}
                  label="Buyer agent commission"
                  htmlFor="buyerAgentCommission"
                >
                  <div>
                    <Percent
                      {...getProvidablePercentInputProps('dp_buyers_agent_commission_rate')}
                      id="buyerAgentCommission"
                    />
                  </div>
                </Row>
                <Row
                  className={styles.detailsRow}
                  label="Seller agent commission"
                  htmlFor="sellerAgentCommission"
                >
                  <div>
                    <Percent
                      {...getProvidablePercentInputProps('dp_sellers_agent_commission_rate')}
                      id="sellerAgentCommission"
                    />
                  </div>
                </Row>
              </Toggle.Show>
            </Toggle>
            <Toggle>
              <Summary title="Property ownership costs" className={styles.spacingLeft}>
                <span />
                <Money
                  {...getProvidableDisplayProps('departing_property_ownership_expenses')}
                  data-testid="propertyOwnershipCostsCalculatedAmount"
                />
              </Summary>
              <Toggle.Show>
                {Object.entries(propertyOwnershipCostsProvidableMapping).map(
                  ([expenseSlug, providable]: [ProviderLeadExpenseSlug, keyof TradeInProvidable]) =>
                    getExpenseBySlug(expenseSlug) ? (
                      <Row
                        key={expenseSlug}
                        className={styles.detailsRow}
                        label={getExpenseBySlug(expenseSlug).displayName}
                        htmlFor={toCamelCase(getExpenseBySlug(expenseSlug).displayName)}
                      >
                        {tradeInProvidableRates.includes(providable) ? (
                          <Percent
                            {...getProvidablePercentInputProps(providable)}
                            className={styles.smallInput}
                            id={toCamelCase(getExpenseBySlug(expenseSlug).displayName)}
                          />
                        ) : (
                          <Money
                            {...getProvidableInputProps(providable)}
                            className={styles.smallInput}
                            id={toCamelCase(getExpenseBySlug(expenseSlug).displayName)}
                          />
                        )}
                        <Money
                          {...getExpenseDisplayProps(expenseSlug)}
                          className={styles.displayValue}
                          data-testid={`${toCamelCase(
                            getExpenseBySlug(expenseSlug).displayName
                          )}CalculatedAmount`}
                        />
                      </Row>
                    ) : null
                )}
                {getExpensesByCategory('ownership_expenses')
                  .filter(
                    expense => !propertyOwnershipCostsProvidableMapping.hasOwnProperty(expense.slug)
                  )
                  .map(expense => (
                    <Row
                      key={expense.id}
                      className={styles.detailsRow}
                      label={expense.displayName}
                      htmlFor={toCamelCase(`property${expense.displayName}`)}
                    >
                      <Money
                        {...getExpenseInputProps(expense)}
                        id={toCamelCase(`property${expense.displayName}`)}
                        data-testid={toCamelCase(`property${expense.displayName}`)}
                      />
                    </Row>
                  ))}
              </Toggle.Show>
            </Toggle>
            <Toggle>
              <Summary title="Transaction costs" className={styles.spacingLeft}>
                <span />
                <Money
                  {...getProvidableDisplayProps('departing_property_transaction_costs')}
                  data-testid="transactionCostsCalculatedAmount"
                />
              </Summary>
              <Toggle.Show>
                {getExpenseBySlug('target_city_transfer_tax') && (
                  <Row
                    className={styles.detailsRow}
                    label={getExpenseBySlug('target_city_transfer_tax').displayName}
                    htmlFor="cityTransferTaxes"
                  >
                    <Percent
                      {...getProvidablePercentInputProps('dp_local_transfer_tax_rate')}
                      id="cityTransferTaxes"
                    />
                    <Money
                      {...getExpenseDisplayProps('target_city_transfer_tax')}
                      data-testid="cityTransferTaxesCalculatedAmount"
                    />
                  </Row>
                )}
                {getExpensesByCategory('transaction_expenses')
                  .filter(expense => expense.slug !== 'target_city_transfer_tax')
                  .sort(sortOtherLast)
                  .map(expense => (
                    <Row
                      className={styles.detailsRow}
                      key={expense.slug}
                      label={expense.displayName}
                      htmlFor={toCamelCase(`transaction${expense.displayName}`)}
                    >
                      <Money
                        {...getExpenseInputProps(expense)}
                        id={toCamelCase(`transaction${expense.displayName}`)}
                        data-testid={toCamelCase(`transaction${expense.displayName}`)}
                      />
                    </Row>
                  ))}
              </Toggle.Show>
            </Toggle>
            <Toggle>
              <Summary title="Repair costs & valuation adjustments" className={styles.spacingLeft}>
                <span />
                <Money
                  {...getProvidableDisplayProps('departing_property_repair_costs')}
                  data-testid="repairCostsCalculatedAmount"
                />
              </Summary>
              <Toggle.Show>
                {getExpenseBySlug('target_property_repair_holdback_cost') && (
                  <Row
                    className={styles.detailsRow}
                    label={getExpenseLabelBySlug('target_property_repair_holdback_cost')}
                    htmlFor="holdbackCost"
                  >
                    <Percent
                      {...getProvidablePercentInputProps('dp_property_repair_holdback_rate')}
                      id="holdbackCost"
                    />
                    <Money
                      {...getExpenseDisplayProps('target_property_repair_holdback_cost')}
                      className={styles.displayValue}
                      data-testid="holdbackCostCalculatedAmount"
                    />
                  </Row>
                )}
                {getExpenseBySlug('target_property_pest_inspections') && (
                  <Row
                    className={styles.detailsRow}
                    label={getExpenseLabelBySlug('target_property_pest_inspections')}
                    htmlFor="pestInspections"
                  >
                    <Money
                      {...getRepairCostsValuationAdjustments('target_property_pest_inspections')}
                      id="pestInspections"
                    />
                  </Row>
                )}
                {getExpenseBySlug('target_valuation_adjustment') &&
                  getExpenseBySlug('target_valuation_adjustment').value !== 0 && (
                    <Row
                      className={styles.detailsRow}
                      label={getExpenseLabelBySlug('target_valuation_adjustment')}
                      htmlFor="valuationAdjustment"
                    >
                      <Money
                        {...getRepairCostsValuationAdjustments('target_valuation_adjustment')}
                        id="valuationAdjustment"
                      />
                    </Row>
                  )}
                {getExpenseBySlug('target_property_repairs') &&
                  getExpenseBySlug('target_property_repairs').value !== 0 && (
                    <Row
                      className={styles.detailsRow}
                      label={getExpenseLabelBySlug('target_property_repairs')}
                      htmlFor="propertyRepairs"
                    >
                      <Money
                        {...getRepairCostsValuationAdjustments('target_property_repairs')}
                        id="propertyRepairs"
                      />
                    </Row>
                  )}
                {getExpenseBySlug('other') && (
                  <Row
                    className={styles.detailsRow}
                    label={getExpenseLabelBySlug('other')}
                    htmlFor="otherRepairCosts"
                  >
                    <Money
                      {...getRepairCostsValuationAdjustments('other')}
                      id="otherRepairCosts"
                      data-testid="otherRepairCosts"
                    />
                  </Row>
                )}
              </Toggle.Show>
            </Toggle>
            <Toggle>
              <Summary title="Listing prep work" className={styles.spacingLeft}>
                <span />
                <Money
                  {...getProvidableDisplayProps('departing_property_listing_prep_fee')}
                  data-testid="listingPrepWorkCalculatedAmount"
                />
              </Summary>
              <Toggle.Show>
                {getExpensesByCategory('listing_preparation_expenses')
                  .sort(sortOtherLast)
                  .map(expense => (
                    <Row
                      className={styles.detailsRow}
                      key={expense.slug}
                      label={expense.displayName}
                      htmlFor={toCamelCase(expense.displayName)}
                    >
                      <Money
                        {...getExpenseInputProps(expense)}
                        id={toCamelCase(expense.displayName)}
                        data-testid={toCamelCase(`listingPreparation${expense.displayName}`)}
                      />
                    </Row>
                  ))}
              </Toggle.Show>
            </Toggle>
            <Row
              label="HomeLight Trade-In fee"
              className={styles.spacingLeftNonArrow}
              htmlFor="homeLightTradeInFee"
            >
              <Percent
                {...getProvidablePercentInputProps('dp_target_trade_in_fee_rate')}
                allowEmptyFormatting
                id="homeLightTradeInFee"
              />
              <Money
                {...getProvidableDisplayProps('dp_target_trade_in_fee')}
                data-testid="homeLightTradeInFeeCalculatedAmount"
              />
            </Row>
          </Toggle.Show>
        </Toggle>
        <Total label="Estimated upfront proceeds">
          <Money
            {...getProvidableDisplayProps('dp_upfront_proceeds_to_client')}
            className={styles.total}
            data-testid="estimatedUpfrontProceeds"
          />
        </Total>
        <Row label="Estimated loan payoff" htmlFor="estimatedLoanPayoff">
          <span />
          <Money
            {...getProvidableInputProps('departing_property_external_loan_payoff')}
            id="estimatedLoanPayoff"
          />
        </Row>
        <Row label="Solar loan balance" htmlFor="solarLoanBalance">
          <span />
          <Money
            {...getProvidableInputProps('departing_property_solar_loan_balance')}
            id="solarLoanBalance"
          />
        </Row>
        <Toggle>
          <Summary title={<strong>Estimated closing costs*</strong>}>
            <Percent
              value={getTotalClosingCostsRate().toString()}
              displayType="text"
              className={styles.removeAccent}
              data-testid="estimatedClosingCostsCalculatedRate"
            />
            <Money
              {...getProvidableDisplayProps('dp_target_client_closing_costs')}
              className={styles.total}
              data-testid="estimatedClosingCostsCalculatedAmount"
            />
          </Summary>
          <Toggle.Show>
            <Row
              label="Estimated closing costs %"
              className={styles.spacingLeftNonArrow}
              htmlFor="estimatedClosingCosts"
            >
              <Percent
                {...getProvidablePercentInputProps('dp_target_client_closing_costs_rate')}
                allowEmptyFormatting
                id="estimatedClosingCosts"
              />
            </Row>
            {payload.providable.dp_local_transfer_tax_rate ? (
              <>
                <Row
                  label={`Split ${formatDisplayPercentValue(
                    payload.providable.dp_local_transfer_tax_rate
                  ).toString()}% City Transfer Tax`}
                  className={styles.spacingLeftNonArrow}
                  htmlFor="splitCityTransferTax"
                >
                  <SelectInput
                    className={styles.smallInput}
                    options={[
                      { name: 'No', value: 1 },
                      { name: 'Yes', value: 0.5 }
                    ]}
                    {...getProvidableNumberProps('dp_local_transfer_tax_split_rate')}
                    id="splitCityTransferTax"
                  />
                </Row>
                <Row label="Adjusted City transfer tax" className={styles.spacingLeftNonArrow}>
                  <Percent
                    value={formatDisplayPercentValue(
                      payload.providable.dp_local_transfer_tax_rate *
                        payload.providable.dp_local_transfer_tax_split_rate
                    ).toString()}
                    displayType="text"
                    className={styles.removeAccent}
                    data-testid="adjustedCityTransferTax"
                  />
                </Row>
              </>
            ) : null}
          </Toggle.Show>
        </Toggle>
        <Final label="Estimated equity for new home purchase">
          <Money
            {...getProvidableDisplayProps('dp_target_new_home_purchase_equity')}
            className={styles.total}
            allowNegative
            data-testid="estimatedEquityForNewHomePurchase"
          />
        </Final>
        <Section>
          <hr />
        </Section>
        <Section>When HomeLight sells your property:</Section>
        <Row label="Market sale price" htmlFor="marketSalePrice">
          <span />
          <Money {...getProvidableInputProps('dp_estimated_sale_price')} id="marketSalePrice" />
        </Row>
        <Separator>Actual costs to sell the home:</Separator>
        <Row label="Agent fees">
          <span />
          <Money
            value={getMarketSalePriceExpenseBySlug('target_total_agent_commission')
              ?.value.toFixed(0)
              .toString()}
            displayType="text"
            data-testid="summaryAgentFees"
          />
        </Row>
        <Row label="Property ownership">
          <span />
          <Money
            {...getProvidableDisplayProps('departing_property_ownership_expenses')}
            data-testid="summaryPropertyOwnership"
          />
        </Row>
        <Row label="Transaction costs">
          <span />
          <Money
            {...getProvidableDisplayProps('departing_property_transaction_costs')}
            data-testid="summaryTransactionCosts"
          />
        </Row>
        <Row label="Repairs & value adjustments">
          <span />
          <Money
            {...getProvidableDisplayProps('departing_property_repair_costs')}
            data-testid="summaryRepairsValueAdjustments"
          />
        </Row>
        <Row label="Listing prep work">
          <span />
          <Money
            {...getProvidableDisplayProps('departing_property_listing_prep_fee')}
            data-testid="summaryListingPrepWork"
          />
        </Row>
        <Row label="HomeLight Trade-In fee">
          <span />
          <Money
            value={getMarketSalePriceProvidable('dp_target_trade_in_fee')?.toFixed(0).toString()}
            displayType="text"
            data-testid="summaryHomeLightTradeInFee"
          />
        </Row>
        <Final label="Net proceeds from market sale">
          <Money
            value={getNetProceedsFromMarketSale().toFixed(0).toString()}
            displayType="text"
            className={styles.total}
            data-testid="summaryNetProceedsFromMarketSale"
          />
        </Final>
        <Row label={<strong>Received upfront proceeds</strong>}>
          <span />
          <Money
            value={
              valueOrZero(payload.providable.dp_estimated_sale_price) === 0
                ? '0'
                : payload.providable.dp_upfront_proceeds_to_client.toFixed(0).toString()
            }
            displayType="text"
            className={styles.total}
            data-testid="summaryReceivedUpfrontProceeds"
          />
        </Row>
        <Row label="Additional proceeds after market sale">
          <span />
          <Money
            value={getAdditionalProceedsAfterMarketSale().toFixed(0).toString()}
            displayType="text"
            allowNegative
            data-testid="summaryAdditionalProceedsAfterMarketSale"
          />
        </Row>
        <Final label="Estimated total equity received">
          <Money
            value={getEstimatedTotalEquityReceived().toFixed(0).toString()}
            displayType="text"
            allowNegative
            className={styles.total}
            data-testid="summaryEstimatedTotalEquityReceived"
          />
        </Final>
      </Table>
      <p className={styles.disclaimer}>
        *The fees, expenses, and projected proceeds detailed above are estimates only and are not
        binding on client, agent, or HomeLight. Estimate of proceeds is in no way guaranteed.
        Closing costs, such as local transfer taxes, title and settlement costs, property taxes,
        etc., are also estimated and are generally payable to third parties. Closing costs will vary
        based on the location of the home you are looking to sell.
      </p>
    </div>
  )
}
