import { createContext, useContext } from 'react'
import { UseMutationResult } from 'react-query'
import { useAuth } from './useAuth'

export interface AuthContextType {
  signIn: UseMutationResult
  signOut: UseMutationResult
  token: string
  clearToken: () => void
}

const AuthContext = createContext<AuthContextType | null>(null)
export const useAuthContext = () => useContext(AuthContext)

export const AuthProvider = ({ children }) => {
  return <AuthContext.Provider value={useAuth()}>{children}</AuthContext.Provider>
}
