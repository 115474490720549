import { Dispatch, SetStateAction, useEffect, useState } from "react"

export const useExitWarning = (): {
  setUnsavedForm: Dispatch<SetStateAction<boolean>>;
  unsavedForm: boolean;
} => {

  const [unsavedForm, setUnsavedForm] = useState(false);
  useEffect(() => {
    const beforeUnloadHandler = (event: BeforeUnloadEvent) => {
      (event || window.event).returnValue = '';
      return; // Gecko + Webkit, Safari, Chrome etc.
    };

    if (unsavedForm) {
      window.addEventListener('beforeunload', beforeUnloadHandler);
    } else {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    }
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, [unsavedForm]);

  return { setUnsavedForm, unsavedForm }
}
