import { createContext, useContext } from 'react'
import { useCurrentUser } from './useCurrentUser'
import { CurrentUser } from 'models'

export interface CurrentUserContextType {
  isLoading: boolean
  isError?: boolean
  currentUser: CurrentUser
}

const CurrentUserContext = createContext<CurrentUserContextType | null>(null)
export const useCurrentUserContext = () => useContext(CurrentUserContext)

export const CurrentUserProvider = ({ children }) => {
  return (
    <CurrentUserContext.Provider value={useCurrentUser()}>{children}</CurrentUserContext.Provider>
  )
}
