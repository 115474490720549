import React from 'react'
import PropTypes from 'prop-types'
import { cx } from 'utils'
import styles from './ActionBar.module.scss'

export const ActionBar = ({ className, children }) => {
  return <div className={cx(className, styles.actionBar)}>{children}</div>
}

ActionBar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string
}

ActionBar.defaultProps = {
  className: undefined
}
