import { ReactNode } from 'react'
import styles from './Table.module.scss'

type Props = {
  children: ReactNode
}

export const Separator = ({ children }: Props) => {
  return (
    <tr className={styles.separator}>
      <td colSpan={2}>{children}</td>
    </tr>
  )
}
