import {
  ProviderLeadExpense,
  ProviderLeadExpenseSlug,
  ProviderLeadExpenseCategory,
  TradeInProvidable
} from 'models'
import { User } from 'models/User'

export type EconomicModelPayload = {
  providable: TradeInProvidable
  expenses: ProviderLeadExpense[]
  performed_by: User
}

export class EconomicModel {
  expenses: ProviderLeadExpense[]
  providable: TradeInProvidable
  performed_by: User
  constructor(economicModel: any) {
    Object.assign(this, economicModel)
  }
  getExpenseBySlug = (slug: ProviderLeadExpenseSlug) =>
    this.expenses.find(expense => expense.slug === slug)

  getExpenseById = (id: string) => this.expenses.find(expense => expense.id === id)

  getExpensesByCategory = (category: ProviderLeadExpenseCategory) =>
    this.expenses.filter(expense => expense.category === category)

  getPayload = () => {
    return {
      providable: this.providable,
      expenses: this.expenses,
      performed_by: this.performed_by
    } as EconomicModelPayload
  }
}
