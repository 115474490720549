import { Children, cloneElement, ReactElement, ReactNode } from 'react'

export const ToggleState = ({
  children,
  on,
  setOn
}: {
  children: any
  on: boolean
  setOn: (on) => void
}) => {
  const toggle = () => setOn(on => !on)

  return Children.map(children, child =>
    cloneElement(child, {
      toggle,
      on
    })
  )
}

ToggleState.Show = ({ on, children }: { on?: boolean; children: ReactNode }) =>
  on ? (children as ReactElement<any>) : null
