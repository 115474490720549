import React from 'react'
import { func, string, oneOfType, arrayOf, node } from 'prop-types'
import { cx } from 'utils'
import { Modal } from 'components/Modal'
import { PopupHeader } from './PopupHeader'
import styles from './Popup.module.scss'

export const Popup = ({ title, onCancel, className, children }) => {
  return (
    <Modal onCancel={onCancel}>
      <div className={cx(styles.content, className)} data-testid="popup-contents">
        <PopupHeader title={title} onCancel={onCancel} />
        {children}
      </div>
    </Modal>
  )
}

Popup.propTypes = {
  onCancel: func,
  className: string,
  children: oneOfType([arrayOf(node), node]),
  title: string
}

Popup.defaultProps = {
  onCancel: null,
  className: undefined,
  children: [],
  title: undefined
}
