import { RouteComponentProps } from '@reach/router'
import { Loading, ToggleState } from 'components'
import { Sidebar } from './Sidebar'
import { EconomicModelForm } from './EconomicModelForm'
import { BbysModelForm } from './BbysModelForm'
import { Snapshots } from './Snapshots'
import styles from './DetailsPage.module.scss'
import {
  EconomicModelProvider,
  ProviderLeadProvider,
  useProviderLeadContext,
  useSidebarContext
} from 'contexts'
import { formatDateTime, formatTime } from 'utils'
import { EconomicModelSnapshot } from 'models'
import { Label } from 'components/Table/Label'
import { PageWrapper } from 'pages/PageWrapper'
import { useWindowSize } from 'hooks'
import { useCurrentUserContext } from 'contexts'
import { useFlags } from 'flagsmith/react';
import { useState } from 'react'

type Props = {
  providerLeadId?: string
  snapshotId?: string
} & RouteComponentProps

const SnapshotTitle = ({ snapshot }: { snapshot: EconomicModelSnapshot }) => (
  <div className={styles.snapshotTitle}>
    <h3 className={styles.title}>
      {snapshot.description ? `${snapshot.description}*` : `${formatDateTime(snapshot.createdAt)}*`}
    </h3>
    {snapshot.description ? (
      <span className={styles.snapshotDate}>Edited {formatTime(snapshot.createdAt)}</span>
    ) : null}
  </div>
)

const DetailsPageContents = ({ snapshotId }: { snapshotId?: string }) => {
  const { providerLead, isLoading } = useProviderLeadContext()
  const { currentUser } = useCurrentUserContext()

  const {
    'updated-econ-model': { enabled: updatedEconModel, value },
  } = useFlags(['updated-econ-model']);
  const leadCreatedAt = providerLead && providerLead.createdAt
  const useNewModel = updatedEconModel && leadCreatedAt > value;

  const { on, setOn } = useSidebarContext()

  const { height } = useWindowSize()

  const sidebarHeight = {
    height
  }
  const [mobileMenuOn, setMobileMenuOn] = useState(false);
  const onOverlayClick = () => mobileMenuOn && setMobileMenuOn(false);

  if (isLoading || !currentUser)
    return (
      <PageWrapper>
        <Loading />
      </PageWrapper>
    )

  return (
    <EconomicModelProvider providerLead={providerLead} snapshotId={snapshotId}>
      <div className={styles.wrapper}>
        <div className={mobileMenuOn ? styles.overlay : null} onClick={onOverlayClick}></div>
        <aside className={styles.sidebar} style={sidebarHeight}>
          <Sidebar providerLead={providerLead}>
            <div className={styles.content}>
              <ToggleState on={on} setOn={setOn}>
                <Label
                  title={`Snapshots ${
                    on ? `(${providerLead?.economicModelSnapshots?.length})` : ''
                  }`}
                />
                <ToggleState.Show>
                  <Snapshots />
                </ToggleState.Show>
              </ToggleState>
            </div>
          </Sidebar>
        </aside>
        <main className={styles.main}>
        {
          useNewModel ? <BbysModelForm providerLead={providerLead} mobileMenuOn={mobileMenuOn} setMobileMenuOn={setMobileMenuOn}/> : (
            <EconomicModelForm>
            {snapshotId ? (
              <SnapshotTitle snapshot={providerLead.getSnapshot(snapshotId)} />
            ) : (
              <h3 className={styles.title}>Trade-In summary</h3>
            )}
          </EconomicModelForm>
          )
        }
        </main>
      </div>
    </EconomicModelProvider>
  )
}

export const DetailsPage = ({ providerLeadId, snapshotId }: Props) => (
  <ProviderLeadProvider providerLeadId={providerLeadId} snapshotId={snapshotId}>
    <DetailsPageContents snapshotId={snapshotId} />
  </ProviderLeadProvider>
)
