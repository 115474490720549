import { getRequest } from 'utils'
import { getProviderLeadsUrl } from 'getUrl'
import { transformEconomicModel } from './transformEconomicModel'

const getSearchParams = payload => {
  return {
    include: 'provider_lead_expenses',
    fields: {
      provider_lead_expenses: [
        'slug',
        'category',
        'description',
        'value',
        'input_keys',
        'calculated'
      ].join(',')
    },
    calculation_type: 'valuation',
    ...payload
  }
}

export const fetchProviderLeadExpenseCalculate = async ({
  providerLeadId,
  payload
}: {
  providerLeadId?: string
  payload?: any
}) => {
  const response = await getRequest(
    getProviderLeadsUrl({
      id: providerLeadId,
      action: 'economic-models',
      searchParams: getSearchParams(payload)
    })
  )

  return await transformEconomicModel(response.data)
}
