import { Link, useNavigate } from '@reach/router'
import { Button, Icon } from 'components'
import { useEconomicModelContext, useProviderLeadContext } from 'contexts'
import { EconomicModelSnapshot } from 'models'
import { useState } from 'react'
import { formatDate, formatDateTime } from 'utils'
import { EconomicModelSaveConfirmation } from '../EconomicModelSaveConfirmation'
import { SnapshotEditForm } from './SnapshotEditForm'
import { SnapshotRemoveConfirmation } from './SnapshotRemoveConfirmation'
import styles from './Snapshots.module.scss'

export const Snapshots = () => {
  const {
    saveSnapshot,
    updateSnapshot,
    saveProviderLead,
    providerLead,
    removeSnapshot,
    isSavingProviderLead,
    isUpdatingSnapshot,
    isRemovingSnapshot
  } = useProviderLeadContext()
  const { payload, snapshotId } = useEconomicModelContext()

  const [editSnapshot, setEditSnapshot] = useState<EconomicModelSnapshot>(null)
  const [deleteSnapshot, setDeleteSnapshot] = useState<EconomicModelSnapshot>(null)
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false)
  const navigate = useNavigate()

  const handleCancelEditSnapshot = () => setEditSnapshot(null)

  const handleSaveSnapshot = async () => {
    await saveSnapshot({
      providerLeadId: providerLead.id,
      payload
    })
  }

  const handleUpdateSnapshot = async (description: string) =>
    await updateSnapshot({
      snapshotId: editSnapshot.id,
      description
    })

  const handleRemoveSnapshot = async () => {
    await removeSnapshot(deleteSnapshot.id)
    if (snapshotId === deleteSnapshot.id) {
      navigate(`/details/${providerLead.id}`)
    }
  }

  const handleSaveProviderLead = () => {
    handleSaveSnapshot()
    saveProviderLead({
      providerLeadId: providerLead.id,
      payload
    })
  }

  const handleToggleShowConfirmation = () => setShowSaveConfirmation(show => !show)

  const { pathname } = window.location

  return (
    <>
      {showSaveConfirmation && (
        <EconomicModelSaveConfirmation
          onCancel={handleToggleShowConfirmation}
          onSubmit={handleSaveProviderLead}
          isLoading={isSavingProviderLead}
        />
      )}
      {editSnapshot && (
        <SnapshotEditForm
          isLoading={isUpdatingSnapshot}
          onCancel={handleCancelEditSnapshot}
          onSubmit={handleUpdateSnapshot}
          initialName={editSnapshot.description}
        />
      )}
      {deleteSnapshot && (
        <SnapshotRemoveConfirmation
          isLoading={isRemovingSnapshot}
          onCancel={() => setDeleteSnapshot(null)}
          onSubmit={handleRemoveSnapshot}
        />
      )}
      <div className={styles.snapshotHeader}>
        <Button as="primary" isBlock onClick={handleToggleShowConfirmation}>
          {isSavingProviderLead ? 'Saving' : 'Save to Database'}
        </Button>
      </div>
      <div className={styles.snapshotsList}>
        {providerLead?.economicModelSnapshots
          .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
          .map(snapshot => {
            const to = `/details/${providerLead.id}/snapshot/${snapshot.id}`
            return (
              <div key={snapshot.id} className={styles.snapshotItem}>
                <div className={styles.snapshotItemControl}>
                  <Button noBorder as="icon" onClick={() => setEditSnapshot(snapshot)}>
                    <Icon.Pencil />
                  </Button>
                  <Button as="icon" noBorder onClick={() => setDeleteSnapshot(snapshot)}>
                    <Icon.Close />
                  </Button>
                </div>
                <div className={styles.snapshotTitle}>
                  <Link to={to} className={pathname === to ? styles.snapshotSelected : null}>
                    {snapshot.description ? snapshot.description : formatDate(snapshot.createdAt)}
                  </Link>
                  {snapshot.description ? (
                    <div className={styles.snapshotDate}>{formatDate(snapshot.createdAt)}</div>
                  ) : null}
                </div>
              </div>
            )
          })}
      </div>
      <hr />
      <div>
        <div className={styles.snapshotItem}>
          <div className={styles.snapshotTitle}>
            <Link to={`/details/${providerLead.id}`} className={styles.lastSaved}>
              Last saved
            </Link>
            <div className={styles.snapshotDate}>
              {formatDateTime(providerLead.tradeInLead.updatedAt)}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
