import { tradeInProvidableRates } from '@constants'
import { ActionBar, Button, Form, FormActions, Icon, MaskedInput, Popup } from 'components'
import { useEconomicModelContext } from 'contexts'
import { cx, dictionary, formatDisplayPercentValue, titleize, valueOrZero } from 'utils'
import styles from './EconomicModelSaveConfirmation.module.scss'

type Props = {
  onCancel: () => void
  onSubmit?: () => void
  isLoading?: boolean
}

const isRate = (slug: string) => tradeInProvidableRates.includes(slug)

const DisplayValue = ({ slug, value }: { slug: string; value: number }) => {
  if (isRate(slug)) {
    return (
      <MaskedInput
        value={formatDisplayPercentValue(value)}
        displayType="text"
        thousandSeparator
        isNumericString
        suffix={'%'}
      />
    )
  }

  return (
    <MaskedInput
      value={value?.toFixed(0).toString()}
      displayType="text"
      thousandSeparator
      isNumericString
      prefix={'$'}
    />
  )
}

const DisplayDecreaseIncrese = ({ prevValue, newValue }) =>
  newValue > prevValue ? (
    <Icon.UpLongArrow className={cx(styles.decreaseIncrese, styles.increased)} />
  ) : (
    <Icon.DownLongArrow className={cx(styles.decreaseIncrese, styles.decreased)} />
  )

export const EconomicModelSaveConfirmation = ({ onCancel, onSubmit, isLoading }: Props) => {
  const { actualEconomicModel, payload, getExpenseById } = useEconomicModelContext()

  const handleOnSubmit = async () => {
    await onSubmit()
    onCancel()
  }

  return (
    <Popup title="Confirm Economic Model changes" onCancel={onCancel}>
      <Form onSubmit={handleOnSubmit}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Category</th>
              <th>Field</th>
              <th>Actual value</th>
              <th className={styles.decreaseIncreaseHeader} />
              <th>New value</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(actualEconomicModel.providable)
              .filter(([slug, value]) => value !== payload.providable[slug])
              .map(([slug, value]) => (
                <tr key={slug}>
                  <td>BBYS Field</td>
                  <td>{dictionary(slug)}</td>
                  <td>
                    <DisplayValue value={value} slug={slug} />
                  </td>
                  <td>
                    <DisplayDecreaseIncrese newValue={payload.providable[slug]} prevValue={value} />
                  </td>
                  <td className={styles.newValue}>
                    <DisplayValue value={payload.providable[slug]} slug={slug} />
                  </td>
                </tr>
              ))}
            {actualEconomicModel.expenses
              .filter(expense => expense.value !== valueOrZero(getExpenseById(expense.id)?.value))
              .map(expense => (
                <tr key={expense.id}>
                  <td>{titleize(expense.category)}</td>
                  <td>{expense.displayName}</td>
                  <td>
                    <DisplayValue value={expense.value} slug={expense.slug} />
                  </td>
                  <td>
                    <DisplayDecreaseIncrese
                      newValue={getExpenseById(expense.id)?.value}
                      prevValue={expense.value}
                    />
                  </td>
                  <td className={styles.newValue}>
                    <DisplayValue value={getExpenseById(expense.id)?.value} slug={expense.slug} />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <FormActions>
          <ActionBar>
            <Button as="cancel" onClick={onCancel} size="medium">
              Cancel
            </Button>
            <Button as="submit" size="medium" data-testid="submit-button" disabled={isLoading}>
              {isLoading ? 'Submitting...' : 'Confirm'}
            </Button>
          </ActionBar>
        </FormActions>
      </Form>
    </Popup>
  )
}
