import { Deserializer } from '@homelight/js-jsonapi-serializer'

type Props<T> = {
  transform?: (val: T) => void
  [key: string]: any
}

export const deserializeAsync = async <T>(
  data: T,
  { transform = val => val, ...relationships }: Props<T> = {}
) => {
  const deserializer = new Deserializer({
    keyForAttribute: 'camelCase',
    transform,
    ...relationships
  })
  return await deserializer.deserialize(data)
}
