export const titleize = (str: string, separator?: string) => {
  if (!str) {
    return ''
  }
  const sep = separator && separator.constructor !== Number ? separator : /[\s_-]+/g
  return str
    .split(sep)
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}
