import {
  Row,
  Table,
  MoneyMaskedInput as Money
} from 'components'
import styles from './BbysModelForm.module.scss'
import { TotalMarketSellingCosts } from "./TotalMarketSellingCosts"
import { TradeInProvidable } from 'models'
import { valueOrZero } from 'utils'
import { useEconomicModelContext } from 'contexts'

export const MarketSaleTab = ({
  className,
  handleOnBlur,
  getProvidablePercentInputProps,
  handleChangeExpense,
  getProvidableDisplayProps,
}) => {

  const { payload, set, economicModel } = useEconomicModelContext()
  const { getExpenseBySlug } = economicModel

  const handleChangeProvidable = (value: number, slug: keyof TradeInProvidable) => {
    set('providable', {
      ...payload.providable,
      [slug]: value
    })
  }

  const getProvidableInputProps = (slug: keyof TradeInProvidable) => {
    return {
      value: payload.providable[slug]?.toFixed(0).toString(),
      onChange: val => handleChangeProvidable(val, slug),
      onBlur: handleOnBlur,
      className: styles.moneyInput
    }
  }
  const getAgentFees = () => {
    return (valueOrZero((payload.providable.dp_buyers_agent_commission_rate + payload.providable.dp_sellers_agent_commission_rate) * payload.providable['dp_estimated_sale_price']))
  }

  const getBbysFee = () => {
    const percentage = payload.providable['dp_target_trade_in_fee_rate'];
    const minFee = percentage > 0.02 ? 11_000 : 8_000;
    const baseFee = payload.providable['dp_estimated_sale_price'] * percentage
    const finalFee = baseFee > minFee ? baseFee : minFee
    return valueOrZero(finalFee)
  }

  const getClosingClosts = () => {
    return (payload.providable['dp_estimated_sale_price'] * payload.providable['dp_target_client_closing_costs_rate'])
  }

  const getTotalCosts = () => {
    return(
      getAgentFees() +
      getBbysFee() +
      getClosingClosts() +
      getExpenseBySlug('target_miscellaneous_costs')?.value +
      getExpenseBySlug('target_property_pest_inspections')?.value
    )
  }


  const netProceeds = Math.round((valueOrZero(payload.providable['dp_estimated_sale_price']) -
                      valueOrZero(getTotalCosts()) - 
                      valueOrZero(payload.providable['dp_first_position_payoffs'])) / 100) * 100


  const getEstimatedAddtlProceeds = () => {
    return (
      netProceeds - payload.providable['dp_target_new_home_purchase_equity']
    )
  }

  return (
    <div className={className}>
      <Table>
        <h3>Market Scenario Summary:</h3>
          <Row label="Market sale price" htmlFor="marketSalePrice" labelClassName={styles.label}>
            <div className={styles.cell}>
              <Money
                {...getProvidableInputProps('dp_estimated_sale_price')}
              />
              <div className={styles.emptyVal}>-</div>
            </div>
          </Row>
          <hr />
            <TotalMarketSellingCosts
              totalCosts={getTotalCosts()}
              agentFees={getAgentFees()}
              bbysFee={getBbysFee()}
              closingCosts={getClosingClosts()}
              getProvidablePercentInputProps={getProvidablePercentInputProps}
              handleChangeExpense={handleChangeExpense}
              handleOnBlur={handleOnBlur}
          />
        <hr />
        <Row
          label="First Position Payoffs:"
          className={styles.totalRow}
          labelClassName={styles.label}
          htmlFor="bbysFee"
        >
          <div className={styles.cell}>
            <div className={styles.emptyVal}>-</div>
            <Money
              {...getProvidableDisplayProps('dp_first_position_payoffs')}
              labelClassName={styles.label}
              data-testid="firstPositionPayoffs"
            />
          </div>
        </Row>
        <Row
          label="First position lien balance"
          labelClassName={styles.label}
          htmlFor="firstPositionLienBalance"
        >
          <div className={styles.cell}>
            <div className={styles.emptyVal}>-</div>
            <Money
              {...getProvidableInputProps('departing_property_external_loan_payoff')}
              data-testid="firstPositionLienBalance"
            />
          </div>
        </Row>
        <Row
          label="Solar lease payoff"
          labelClassName={styles.label}
          htmlFor="solarLeasePayoff"
        >
          <div className={styles.cell}>
            <div className={styles.emptyVal}>-</div>
            <Money
              {...getProvidableInputProps('dp_solar_lease_payoff')}
              data-testid="solarLeasePayoff"
            />
          </div>
        </Row>
        <hr />
        <Row
          label="Net Proceeds Upon Sale:"
          className={styles.highlitedVal}
          labelClassName={styles.label}
          htmlFor="netProceedsFromSale"
        >
        <div className={styles.cell}>
          <div className={styles.emptyVal}>-</div>
            <Money
              value={netProceeds}
              className={styles.moneyDisplay}
              labelClassName={styles.label}
              displayType='text'
              allowNegative
              data-testid="maxDownpaymentEquity"
            />
          </div>
        </Row>
        <Row
          label="Total Equity Unlock Amount"
          labelClassName={styles.label}
          htmlFor="estimatedEquityForNewHomePurchase"
        >
          <div className={styles.cell}>
            <div className={styles.emptyVal}>-</div>
            <Money
              {...getProvidableDisplayProps('dp_target_new_home_purchase_equity')}
              allowNegative
              data-testid="estimatedEquityForNewHomePurchase"
            />
          </div>
        </Row>
        <hr />
        <Row
          label="Estimated additional proceeds after market sale:"
          className={styles.finalVal}
          labelClassName={styles.label}
          htmlFor="estimatedEquityForNewHomePurchase"
        >
        <div className={styles.cell}>
          <div className={styles.emptyVal}></div>
            <Money
              value={getEstimatedAddtlProceeds()}
              className={styles.moneyDisplay}
              labelClassName={styles.label}
              displayType='text'
              allowNegative
              data-testid="maxDownpaymentEquity"
            />
          </div>
        </Row>
      </Table>
    </div>
  )
}
