import { createContext, useContext, useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { CloseIcon, ErrorIcon } from './icons'

type Toast = {
  id: number
  message: string
  type: 'success' | 'error' | 'warning' | 'info'
  duration: number
}

const ToastComponent = ({ id, message, duration }: Toast) => {
  const [visible, setVisible] = useState(true)
  const { close } = useContext(ToastContext)

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false)
    }, duration)

    return () => clearTimeout(timer)
  }, [duration])

  const handleAnimationEnd = () => {
    if (!visible) {
      close(id)
    }
  }

  const className = `${styles.toast} ${visible ? styles.toastShow : styles.toastHide}`

  return (
    <div className={className} onAnimationEnd={handleAnimationEnd}>
      <ErrorIcon />
      <p className={styles.message}>{message}</p>
      <button className={styles.closeBtn} onClick={() => close(id)}>
        <CloseIcon />
      </button>
    </div>
  )
}

type ToastContextType = {
  toastStack: Toast[]
  error: (message?: string) => void
  close: (id: number) => void
}

const ToastContext = createContext<ToastContextType | null>(null)

export const ToastProvider = ({ children }) => {
  const [toastStack, setToastStack] = useState<Toast[]>([])

  const error = (message = 'Something went wrong') => {
    const newToast = {
      id: Date.now(),
      type: 'error' as Toast['type'],
      message,
      duration: 3000
    }

    setToastStack(prev => [...prev, newToast])
  }

  const close = (id: number) => {
    setToastStack(prev => prev.filter(p => p.id !== id))
  }

  return (
    <ToastContext.Provider value={{ toastStack, error, close }}>
      <div className={styles.toastContainer}>
        {toastStack.map(toast => (
          <ToastComponent key={toast.id} {...toast} />
        ))}
      </div>
      {children}
    </ToastContext.Provider>
  )
}

export const useToast = () => {
  const context = useContext(ToastContext)
  if (!context) {
    throw new Error('useToast must be called within a ToastProvider')
  }
  return context
}
