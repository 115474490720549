import { ReactNode } from 'react'
import { ReactComponent as Logo } from 'assets/logo.svg'
import styles from './PageWrapper.module.scss'

export const PageWrapper = ({ children }: { children?: ReactNode }) => {
  return (
    <div className={styles.pageWrapper}>
      <div className={styles.logo}>
        <Logo height="46.5" width="150" />
      </div>
      {children}
    </div>
  )
}
