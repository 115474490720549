import { ReactNode } from 'react'
import { cx } from 'utils'
import styles from './Form.module.scss'

export type FormFieldProps = {
  children?: ReactNode
  title?: string
  htmlFor?: string
  className?: string
  inline?: boolean
  required?: boolean
  hint?: ReactNode
  error?: ReactNode
}

export const FormField = ({
  children,
  title,
  htmlFor,
  className,
  inline,
  required,
  hint,
  error,
  ...props
}: FormFieldProps) => {
  let requiredStar = null
  if (required) {
    requiredStar = <span className={styles.required}>*</span>
  }

  if (htmlFor) {
    return (
      <div
        className={cx(styles.field, styles.inlineMode, className)}
        data-testid={`${title}-form-field`}
        {...props}
      >
        {children}
        <label htmlFor={htmlFor} data-testid={`${title}-label`}>
          {title}
          {requiredStar}
        </label>
      </div>
    )
  }

  const hintOrError = (() => {
    if (error) {
      return <span className={cx(styles.hint, styles.errorMsg, styles.show)}>{error}</span>
    }
    if (hint) {
      return <span className={cx(styles.hint, styles.show)}>{hint}</span>
    }
    if (!inline) {
      return <span className={cx(styles.hint)} />
    }
    return null
  })()

  return (
    <div
      className={cx(
        styles.field,
        className,
        inline && styles.inlineMode,
        error ? styles.error : null
      )}
      data-testid={`${title}-form-field`}
      {...props}
    >
      <label htmlFor={htmlFor} data-testid={`${title}-label`}>
        {title}
        {requiredStar}
      </label>
      {children}
      {hintOrError}
    </div>
  )
}
