import { ReactNode } from 'react'
import { cx } from 'utils'
import styles from './Form.module.scss'

type Props = {
  children: ReactNode
  confirmation?: string
  error?: string
  className?: string
}

export const FormActions = ({ children, confirmation, error, className, ...props }: Props) => {
  return (
    <div className={cx(styles.actions, className)} {...props} data-testid="form-actions">
      {children}
      {error ? <span className={styles.error}>{error}</span> : null}
      {!error && confirmation ? <span className={styles.confirmation}>{confirmation}</span> : null}
    </div>
  )
}
