import { fetchCurrentUser } from './services'
import { useAuthContext } from 'contexts'
import { useQuery } from 'hooks'

export const useCurrentUser = () => {
  const { token } = useAuthContext()

  const { data: currentUser, error, isError, isLoading } = useQuery(
    'current-user',
    fetchCurrentUser,
    {
      enabled: !!token,
      refetchOnWindowFocus: false
    }
  )

  return {
    currentUser,
    error,
    isError,
    isLoading
  }
}
