import { useAuthContext } from 'contexts'
import { Button, Form, FormActions, FormField, PasswordInput, TextInput } from 'components'
import { useMap } from 'react-use'
import styles from './SignInForm.module.scss'

export const SignInForm = () => {
  const [payload, { set }] = useMap({ email: '', password: '' })
  const { signIn } = useAuthContext()

  const handleSignIn = () => {
    signIn.mutateAsync({ email: payload.email.toLowerCase(), password: payload.password })
  }

  return (
    <Form title="Sign In" onSubmit={handleSignIn} className={styles.form}>
      <FormField title="Email">
        <TextInput value={payload.email} onChange={val => set('email', val)} />
      </FormField>

      <FormField title="Password">
        <PasswordInput value={payload.password} onChange={val => set('password', val)} />
      </FormField>

      <FormActions>
        <Button as="submit" onClick={handleSignIn}>
          Submit
        </Button>
      </FormActions>
    </Form>
  )
}
