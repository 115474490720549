import { ReactNode } from 'react'
import styles from './Table.module.scss'

type Props = {
  children: ReactNode
}

export const Table = ({ children }: Props) => {
  return (
    <table className={styles.table}>
      <tbody>{children}</tbody>
    </table>
  )
}
