import { ActionBar, Button, Popup, Form, FormActions } from 'components'

type Props = {
  isLoading?: boolean
  onCancel: () => void
  onSubmit: () => void
}

export const SnapshotRemoveConfirmation = ({ onCancel, onSubmit, isLoading }: Props) => {
  const handleOnSubmit = async () => {
    await onSubmit()
    onCancel()
  }

  return (
    <Popup title="Confirm Action" onCancel={onCancel}>
      <Form onSubmit={handleOnSubmit}>
        <p>Delete snapshot?</p>
        <FormActions>
          <ActionBar>
            <Button as="cancel" onClick={onCancel} size="medium">
              Cancel
            </Button>
            <Button as="submit" size="medium" data-testid="submit-button" disabled={isLoading}>
              {isLoading ? 'Submitting...' : 'Confirm'}
            </Button>
          </ActionBar>
        </FormActions>
      </Form>
    </Popup>
  )
}
