import { byAttr } from 'utils'

type Roles = { sales_app_dev: boolean; sales_app_admin: boolean }

const getRoles = (user): Roles => {
  const importantRoles = ['sales_app_dev', 'sales_app_admin']
  return importantRoles.reduce((acc, role) => {
    return { ...acc, [role]: !!user.roles.find(byAttr('name', role)) }
  }, {} as Roles)
}

export class CurrentUser {
  id: string
  email: string
  firstName: string
  name: string
  roles: Roles
  lastName: string

  constructor(user) {
    Object.assign(this, user)
    this.name = `${user.firstName} ${user.lastName}`
    this.roles = getRoles(user)
  }
}
