import { postRequest } from 'utils'
import { getUserAuthUrl } from 'getUrl'

const errorMessages = {
  401: 'The credentials are not valid'
}

export const postSignin = async (payload): Promise<any> => {
  try {
    const result = await postRequest(getUserAuthUrl({ action: 'create_token' }), payload)
    return result.data.token
  } catch (error) {
    const status = error?.response?.status
    if (errorMessages[status]) {
      throw new Error(errorMessages[status])
    }
    throw error
  }
}
