import { Icon } from 'components'
import { ProviderLead } from 'models'
import styles from './Sidebar.module.scss'
import { useSidebar } from './hooks'
import { ReactNode } from 'react'
type Props = {
  providerLead: ProviderLead
  children: ReactNode
}

const Section = ({ title, children }) => (
  <div className={styles.section}>
    <div className={styles.title}>{title}</div>
    {children}
  </div>
)

export const LegacySidebar = ({ providerLead, children }: Props) => {
  const { address, loanOfficer, clientManager } = useSidebar(providerLead)

  return (
    <div>
      {clientManager && (
        <Section title="Client manager">
          <div>{clientManager.name}</div>
          <div className={styles.email}>{clientManager.email}</div>
        </Section>
      )}
      {loanOfficer && (
        <Section title="Mortgage advisor">
          <div>{loanOfficer.name}</div>
          <div className={styles.email}>{loanOfficer.email}</div>
        </Section>
      )}
      {address && (
        <Section title="Property address">
          <div>{address.street}</div>
          <div>{address.cityState}</div>
        </Section>
      )}
      <div className={styles.homeLightHomeLoans}>
        <a href="https://homelighthomeloans.com/mortgage-rates/" target="_blank" rel="noreferrer">
          <span>Mortgage Rates</span>
          <Icon.ExternalLink />
        </a>
      </div>
      <hr />
      {children}
    </div>
  )
}
