import { ChangeEvent, ComponentPropsWithoutRef } from 'react'

export type TextInputProps = {
  value?: string
  onChange?: (val: string) => void
  onBlur?: (val: string) => void
}

export const TextInput = ({
  value,
  onChange,
  onBlur,
  ...props
}: TextInputProps & Omit<ComponentPropsWithoutRef<'input'>, 'onChange'>) => {
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value.length ? e.currentTarget.value : ''
    onChange && onChange(value)
  }
  const handleOnBlur = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value.length ? e.currentTarget.value : ''
    onBlur && onBlur(value)
  }
  return (
    <input
      type="text"
      value={value || ''}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      {...props}
    />
  )
}
