import { LeadResponse } from '@types'

export class Lead {
  id!: number
  loanOfficer?: {
    id: string
    name: string
    email: string
  }
  clientManager?: {
    id: string
    name: string
    email: string
  }
  createdAt!: string
  email!: string
  emailAlt!: string
  fullAddress!: string
  furthestStage!: string
  humanTimeline!: string
  lastCallDate!: string
  lastStageUpdate!: string
  name!: string
  notesToAgents!: string
  partnerName!: string
  price!: number
  propertyType!: string
  salesNotes!: string
  source!: string
  stage!: string
  userType!: string

  constructor(lead: LeadResponse) {
    Object.assign(this, lead)

    const clientManager = lead.leadUsers
      ? lead.leadUsers.find(leadUser => leadUser.role === 'client_manager')
      : null
    const loanOfficer = lead.leadUsers
      ? lead.leadUsers.find(leadUser => leadUser.role === 'loan_officer')
      : null

    this.loanOfficer = loanOfficer ? loanOfficer.user : null
    this.clientManager = clientManager ? clientManager.user : null
  }
}
