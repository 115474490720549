import { ReactNode } from 'react'
import styles from './Table.module.scss'

type Props = {
  label?: string
  children: ReactNode
  titleClassName?: string
}

export const Total = ({ label, children, titleClassName }: Props) => {
  return (
    <tr className={styles.total}>
      <td className={titleClassName || styles.label}>{label}</td>
      <td />
      <td>{children}</td>
    </tr>
  )
}
