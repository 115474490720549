import { getRequest } from 'utils'
import { getCurrentUserUrl } from 'getUrl'
import { transformCurrentUser } from './transformCurrentUser'

export const fetchCurrentUser = async () => {
  const response = await getRequest(
    getCurrentUserUrl({
      searchParams: {
        include: 'roles',
        'fields[user]': 'email,first_name,last_name,available,phone_twilio,roles',
        'fields[role]': 'name'
      }
    })
  )
  return transformCurrentUser(response.data)
}
