import { ChangeEvent } from 'react'
import { NumberFormatProps } from 'react-number-format'
import { default as NumberFormat, NumberFormatValues } from 'react-number-format'

type Props = {
  value?: string | number
  onChange?: (val: number) => void
  onBlur?: (val: string) => void
}

export type MaskedInputProps = Props & NumberFormatProps

export const MaskedInput = ({ value, onChange, onBlur, ...props }: MaskedInputProps) => {
  const handleOnChange = (values: NumberFormatValues) => {
    onChange && onChange(values.floatValue)
  }
  const handleOnBlur = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value.length ? e.currentTarget.value : ''
    onBlur && onBlur(value)
  }

  return (
    <NumberFormat
      value={value || null}
      onValueChange={handleOnChange}
      onBlur={handleOnBlur}
      {...props}
    />
  )
}
