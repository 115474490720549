import { deleteRequest } from 'utils'
import {getSnapshotsUrl} from 'getUrl'

export const deleteSnapshot = ({ snapshotId }): Promise<any> => {
  return deleteRequest(
    getSnapshotsUrl({
      id: snapshotId
    })
  )
}
