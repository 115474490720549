import { putRequest } from 'utils'
import { getSnapshotsUrl } from 'getUrl'

export const putProviderLeadSnapshot = ({ snapshotId, description }): Promise<any> => {
  return putRequest(
    getSnapshotsUrl({
      id: snapshotId
    }),
    { description }
  )
}
