import { ProviderLeadResponse } from '@types'
import {
  Lead,
  ProviderLeadExpense,
  TradeInLead,
  EconomicModel,
  EconomicModelSnapshot
} from 'models'

export class ProviderLead {
  createdAt: string
  followUpDate: string
  furthestStage: string
  id: string
  introSentAt: string
  reasonForFail: string
  representedProduct: string
  stage: string
  lead: Lead
  tradeInLead: TradeInLead
  snapshots: ProviderLead[]
  updatedAt: string
  providerLeadExpenses: ProviderLeadExpense[]
  economicModelSnapshots: EconomicModelSnapshot[]
  constructor(providerLead: ProviderLeadResponse) {
    Object.assign(this, providerLead)
    this.lead = providerLead.lead ? new Lead(providerLead.lead) : undefined
    this.tradeInLead = new TradeInLead(providerLead.providable)
    this.providerLeadExpenses =
      providerLead.providerLeadExpenses && providerLead.providerLeadExpenses.length
        ? providerLead.providerLeadExpenses.map(
            providerLeadExpense => new ProviderLeadExpense(providerLeadExpense)
          )
        : []
    this.economicModelSnapshots = providerLead.economicModelSnapshots?.map(
      snapshot => new EconomicModelSnapshot(snapshot)
    )
  }

  toEconomicModel = () => {
    return new EconomicModel({
      providable: this.tradeInLead.getProvidable(),
      expenses: this.providerLeadExpenses
    })
  }

  fromSnapshotToEconomicModel = (snapshotId: string) => {
    const snapshot = this.economicModelSnapshots.find(snapshot => snapshot.id === snapshotId)
    return new EconomicModel({
      providable: snapshot.tradeInLead.getProvidable(),
      expenses: snapshot.providerLeadExpenses,
      performed_by: snapshot.performedBy
    })
  }

  getSnapshot = (snapshotId: string) =>
    this.economicModelSnapshots.find(snapshot => snapshot.id === snapshotId)
}
