import { getRequest } from 'utils'
import { getProviderLeadsUrl } from 'getUrl'
import { transformProviderLead } from './transformProviderLead'
import { getSearchParams } from './getSearchParams'

export const fetchProviderLead = async (id: string) => {
  const url = getProviderLeadsUrl({
    id,
    searchParams: getSearchParams()
  })
  const response = await getRequest(url)
  return await transformProviderLead(response.data)
}
