import { Button } from "components";
import { PropertyAddress } from "../BbysModelForm/PropertyAddress"
import { useSidebar } from "./hooks"
import styles from ".././BbysModelForm/SnapshotHistory.module.scss"
import { useCurrentUserContext, useEconomicModelContext, useProviderLeadContext } from "contexts";
import { SnapshotItem } from "../BbysModelForm/SnapshotItem";

export const BbysSidebar = ({providerLead}) => {
  const { address } = useSidebar(providerLead);
  const { saveSnapshot, isSavingProviderLead, saveProviderLead, isUpdatingSnapshot } = useProviderLeadContext();
  const isLoading = isUpdatingSnapshot || isSavingProviderLead;
  const { payload } = useEconomicModelContext()

  const { currentUser } = useCurrentUserContext()
  const buildUser = () => {
    return {
      id: currentUser.id,
      name: currentUser.name
    }
  }
  const handleSaveSnapshot = async () => {
    payload.performed_by = buildUser()
    await saveSnapshot({
      providerLeadId: providerLead.id,
      payload
    })
  }

  const handleSaveProviderLead = () => {
    handleSaveSnapshot()
    saveProviderLead({
      providerLeadId: providerLead.id,
      payload
    })
  }

  const numSnapshots = providerLead?.economicModelSnapshots?.length;

  return (
    <div className={isLoading ? styles.loading : null}>
      <PropertyAddress address={address} className={styles.webPropertyAddress}></PropertyAddress>
      <div className={styles.snapshotHistoryWeb}>
        <Button
          as="primary"
          isBlock
          onClick={handleSaveProviderLead}
          className={styles.footerButton}
        >
          {isSavingProviderLead ? 'Saving' : 'Save to Database'}
        </Button>
        <div className={styles.historyDropdown}>
          <div>{`History (${numSnapshots})`}</div>
        </div>
        <div className={styles.snapshotsList}>
          {providerLead?.economicModelSnapshots
            .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
            .map(snapshot => {
              return <SnapshotItem providerLead={providerLead} snapshot={snapshot}/>
            })}
        </div>
      </div>
    </div>
  )
}