import { ReactNode } from 'react'
import styles from './Table.module.scss'

type Props = {
  label: string
  children: ReactNode
  rowClassName?: string
  cellClassName?: string
}

export const Final = ({ label, children, rowClassName, cellClassName }: Props) => {
  return (
    <>
      <tr>
        <td colSpan={3} />
      </tr>
      <tr className={rowClassName || styles.final}>
        <td className={cellClassName || styles.label}>{label}</td>
        <td />
        <td>{children}</td>
      </tr>
    </>
  )
}
