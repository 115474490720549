import { MouseEvent, ReactNode, useEffect } from 'react'
import { cx } from 'utils'
import styles from './Modal.module.scss'
import { Portal } from 'components/Portal'

type Props = {
  onCancel: (val) => void
  className: string
  children: ReactNode
}
export const Modal = ({ onCancel, className, children }: Props) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => document.body.classList.remove('modal-open')
  }, [])

  const handleOnCancel = (e: MouseEvent<HTMLDivElement>) => {
    if (e.currentTarget === e.target) {
      onCancel(e)
    }
  }

  return (
    <Portal>
      <div className={cx(styles.modal, className)} role="presentation" onClick={handleOnCancel}>
        {children}
      </div>
    </Portal>
  )
}
