import { ReactNode } from 'react'
import styles from './Table.module.scss'

type Props = {
  children: ReactNode
}

export const Section = ({ children }: Props) => {
  return (
    <tr className={styles.section}>
      <td colSpan={3}>{children}</td>
    </tr>
  )
}
