import { EconomicModelResponse } from '@types'
import { ProviderLead } from 'models'
import { deserializeAsync } from 'utils'

export const transformEconomicModel = async (data: unknown): Promise<ProviderLead> => {
  const { providerLeadExpenses, ...providable } = (await deserializeAsync(
    data
  )) as EconomicModelResponse

  return new ProviderLead({
    providable,
    providerLeadExpenses
  })
}
