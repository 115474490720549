import HttpsRedirect from 'react-https-redirect'
import flagsmith from 'flagsmith'
import { FlagsmithProvider } from 'flagsmith/react'
import { QueryClient, QueryClientProvider } from 'hooks'
import { ReactQueryDevtools } from 'react-query/devtools'
import { AuthProvider, CurrentUserProvider, SidebarProvider, useAuthContext } from 'contexts'
import 'scss/global.module.scss'
import { hotjar } from 'react-hotjar'
import { SignInPage } from 'pages/SignInPage'
import { Routes } from 'pages/Routes'
import { ToastProvider } from 'components/Toast'

if (
  process.env.REACT_APP_HOTJAR_ID &&
  (process.env.REACT_APP_ENV === 'staging' || process.env.REACT_APP_ENV === 'production')
) {
  hotjar.initialize(
    process.env.REACT_APP_HOTJAR_ID as unknown as number,
    process.env.REACT_APP_HOTJAR_SV as unknown as number
  )
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 3 * 60_000
    }
  }
})

const AppAuthenticated = () => {
  return (
    <SidebarProvider>
      <ReactQueryDevtools />
      <Routes />
    </SidebarProvider>
  )
}

const Authenticated = () => {
  const { token } = useAuthContext()

  if (!token) {
    return <SignInPage />
  }

  return <AppAuthenticated />
}

export const App = () => {
  return (
    <ToastProvider>
      <HttpsRedirect>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <FlagsmithProvider
              options={{
                environmentID: process.env.REACT_APP_FLAGSMITH_KEY
              }}
              flagsmith={flagsmith}
            >
              <CurrentUserProvider>
                <Authenticated />
              </CurrentUserProvider>
            </FlagsmithProvider>
          </AuthProvider>
        </QueryClientProvider>
      </HttpsRedirect>
    </ToastProvider>
  )
}
