import { ChangeEvent } from 'react'

type Props = {
  value: string
  onChange: (val: string) => void
  enableViewText?: boolean
}

export const PasswordInput = ({ enableViewText, value = '', onChange, ...props }: Props) => {
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.value)
  }
  return <input type="password" value={value} onChange={handleOnChange} />
}
