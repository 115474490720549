import { asArray } from './asArray'

export const byAttr = (attr, value) => item => {
  return item[attr] && asArray(value).filter(a => asArray(item[attr]).includes(a)).length
}

export const byType = types => item =>
  asArray(types)
    .map(x => x?.toLowerCase())
    .includes(item.type?.toLowerCase())

export const byId = ids => item =>
  asArray(ids)
    .map(id => String(id))
    .includes(String(item.id))

export const byValue = value => item => item.value === value
