import { ProviderLead } from 'models'
import { useMap, useUpdateEffect } from 'hooks'
import { EconomicModelContextType } from './EconomicModelProvider'

export const useEconomicModel = (
  providerLead?: ProviderLead,
  snapshotId?: string
): EconomicModelContextType => {
  const actualEconomicModel = providerLead.toEconomicModel()

  const economicModel = snapshotId
    ? providerLead.fromSnapshotToEconomicModel(snapshotId)
    : actualEconomicModel

  const [payload, { set, setAll }] = useMap(economicModel.getPayload())

  useUpdateEffect(() => {
    setAll(economicModel.getPayload())
  }, [snapshotId])

  const getExpenseById = (id: string) => payload.expenses.find(expense => expense.id === id)

  return {
    economicModel,
    actualEconomicModel,
    payload,
    set,
    setAll,
    getExpenseById,
    snapshotId
  }
}
