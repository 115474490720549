export const ErrorIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="14" fill="#F93A2F" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.7349 16L21.6621 12.0728C21.8892 11.8456 21.8892 11.477 21.6621 11.2495L20.7496 10.337C20.5224 10.1099 20.1538 10.1099 19.9263 10.337L15.9995 14.2646L12.0722 10.3374C11.8451 10.1102 11.4765 10.1102 11.249 10.3374L10.3369 11.2495C10.1097 11.4767 10.1097 11.8453 10.3369 12.0728L14.2641 16L10.3369 19.9272C10.1097 20.1544 10.1097 20.5229 10.3369 20.7504L11.2494 21.663C11.4765 21.8901 11.8451 21.8901 12.0726 21.663L15.9995 17.7354L19.9267 21.6626C20.1538 21.8897 20.5224 21.8897 20.7499 21.6626L21.6625 20.7501C21.8896 20.5229 21.8896 20.1544 21.6625 19.9269L17.7349 16Z"
      fill="white"
    />
  </svg>
)

export const CloseIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.27298 8.00019L13.1191 4.15407L13.9122 3.36092C14.0293 3.24391 14.0293 3.05378 13.9122 2.93678L13.0636 2.08813C12.9466 1.97112 12.7565 1.97112 12.6395 2.08813L8.00019 6.7274L3.36092 2.08775C3.24391 1.97075 3.05378 1.97075 2.93678 2.08775L2.08775 2.9364C1.97075 3.05341 1.97075 3.24354 2.08775 3.36054L6.7274 8.00019L2.08775 12.6395C1.97075 12.7565 1.97075 12.9466 2.08775 13.0636L2.9364 13.9122C3.05341 14.0293 3.24354 14.0293 3.36054 13.9122L8.00019 9.27298L11.8463 13.1191L12.6395 13.9122C12.7565 14.0293 12.9466 14.0293 13.0636 13.9122L13.9122 13.0636C14.0293 12.9466 14.0293 12.7565 13.9122 12.6395L9.27298 8.00019Z"
      fill="#8E929C"
    />
  </svg>
)
