import { EconomicModelSnapshotResponse } from '@types'
import { TradeInLead, ProviderLeadExpense } from 'models'
import { User } from 'models/User'

export class EconomicModelSnapshot {
  id: string
  description: string
  tradeInLead: TradeInLead
  providerLeadExpenses: ProviderLeadExpense[]
  createdAt: string
  performedBy?: User

  constructor(economicModelSnapshotResponse: EconomicModelSnapshotResponse) {
    const { payload, ...economicModelSnapshot } = economicModelSnapshotResponse

    this.tradeInLead = new TradeInLead(payload.providable)
    this.providerLeadExpenses =
      payload.expenses && payload.expenses.length
        ? payload.expenses.map(providerLeadExpense => new ProviderLeadExpense(providerLeadExpense))
        : []
    this.performedBy = payload.performedBy

    Object.assign(this, economicModelSnapshot)
  }
}
