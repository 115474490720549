import { Icon } from 'components/Icon'
import { ReactNode } from 'react'
import styles from './Table.module.scss'

type Props = {
  on?: boolean
  title: ReactNode
  toggle?: () => void
}

export const Caret = ({ on, title, toggle }: Props) => (
  <div onClick={toggle} className={on ? styles.flippedLabel : styles.caretLabel }>
    {title}
    {on ? (
      <Icon.Caret data-testid="caret" className={styles.flipped}/>
    ) : (
      <Icon.Caret data-testid="caret"/>
    )}
  </div>
)
