import { createContext, ReactNode, useContext } from 'react'
import { useEconomicModel } from './useEconomicModel'
import { EconomicModel, EconomicModelPayload, ProviderLead, ProviderLeadExpense } from 'models'

export interface EconomicModelContextType {
  economicModel: EconomicModel
  actualEconomicModel: EconomicModel
  payload?: EconomicModelPayload
  snapshotId?: string
  set?: <K extends keyof EconomicModelPayload>(key: K, value: EconomicModelPayload[K]) => void
  setAll?: (newMap: EconomicModelPayload) => void
  getExpenseById?: (id: string) => ProviderLeadExpense
}

const EconomicModelContext = createContext<EconomicModelContextType | null>(null)
export const useEconomicModelContext = () => {
  const context = useContext(EconomicModelContext)
  if (!context) {
    throw new Error('useEconomicModelContext must be called within a EconomicModelProvider')
  }
  return context
}

type Props = {
  children: ReactNode
  providerLead: ProviderLead
  snapshotId?: string
}

export const EconomicModelProvider = ({ children, providerLead, snapshotId }: Props) => {
  return (
    <EconomicModelContext.Provider value={useEconomicModel(providerLead, snapshotId)}>
      {children}
    </EconomicModelContext.Provider>
  )
}
