import { Children, ReactNode } from 'react'
import styles from './Table.module.scss'

type Props = {
  label: ReactNode
  htmlFor?: string
  children: ReactNode
  onClick?: () => void
  className?: string
  labelClassName?: string
}

export const Row = ({ label, htmlFor, children, onClick, className, labelClassName }: Props) => {
  return (
    <tr onClick={onClick} className={className}>
      <td className={labelClassName || styles.label}>
        <label htmlFor={htmlFor}>{label}</label>
      </td>
      {Children.map(children, child => (
        <td>{child}</td>
      ))}
    </tr>
  )
}
