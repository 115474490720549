import React from 'react'
import PropTypes from 'prop-types'

export const Canary = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className={className}>
    <path
      fill="currentColor"
      d="M544 32h-16.36C513.04 12.68 490.09 0 464 0c-44.18 0-80 35.82-80 80v20.98L12.09 393.57A30.216 30.216 0 000 417.74c0 22.46 23.64 37.07 43.73 27.03L165.27 384h96.49l44.41 120.1c2.27 6.23 9.15 9.44 15.38 7.17l22.55-8.21c6.23-2.27 9.44-9.15 7.17-15.38L312.94 384H352c1.91 0 3.76-.23 5.66-.29l44.51 120.38c2.27 6.23 9.15 9.44 15.38 7.17l22.55-8.21c6.23-2.27 9.44-9.15 7.17-15.38l-41.24-111.53C485.74 352.8 544 279.26 544 192v-80l96-16c0-35.35-42.98-64-96-64zm-80 72c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"
    />
  </svg>
)

Canary.propTypes = {
  className: PropTypes.string
}

Canary.defaultProps = {
  className: undefined
}

Canary.displayName = 'Canary'

export const LogoWithText = ({ width, height, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="45 80 420 90"
    width={width}
    height={height}
    {...props}
  >
    <circle cx="90" cy="125" r="40" fill="#fff" />
    <path
      fill="#46b6ff"
      fillRule="evenodd"
      d="M126.1 139.3a40.3 40.3 0 0 0 2.6-14.3 39.4 39.4 0 1 0-78.7 0 38.3 38.3 0 0 0 2.7 14.3l36.7-29.9z"
    />
    <path
      fill="#273653"
      fillRule="evenodd"
      d="M89.4 122.5l31.6 25.9a39.3 39.3 0 0 1-63.3 0zM81.7 146h6.6v-6.5h-6.6zm8.8-6.5H97v6.5h-6.5zm-8.8 8.8h6.6v6.5h-6.6zm8.8 0H97v6.5h-6.5zM178.7 145.8v-20.5h-26.1v20.5h-5.5v-43.1h5.5v17.6h26.1v-17.6h5.6v43.1h-5.6zM191.4 129.6c0-9.4 6.2-17 16-17s16 7.6 16 17-6.1 17-16 17-16-7.7-16-17m26.7 0c0-6.6-3.8-12.5-10.7-12.5s-10.7 5.9-10.7 12.5 3.8 12.5 10.7 12.5 10.7-5.9 10.7-12.5M269 145.8v-22c0-4.1-1.7-6.7-5.9-6.7s-6.9 2.4-8.6 5v23.7h-5v-22c0-4.1-1.7-6.7-6-6.7s-6.7 2.4-8.5 5v23.7h-5v-32.4h5v4.7a14.1 14.1 0 0 1 10.5-5.5c4.9 0 7.7 2.7 8.7 6.1a13.4 13.4 0 0 1 10.8-6.1c5.9 0 9 3.3 9 9.8v23.4zM280.6 129.6c0-9.4 6.7-17 15.9-17S312 120.3 312 130v1.2h-26.1c.4 6.1 4.6 11.2 11.6 11.2a14.1 14.1 0 0 0 10-4.1l2.5 3.2a17.7 17.7 0 0 1-12.9 5.1c-9.6 0-16.5-6.9-16.5-17m15.8-12.8c-6.9 0-10.3 5.8-10.5 10.7h21.2c-.1-4.8-3.3-10.7-10.7-10.7M318.7 145.8v-43.1h5.5v38.1H345v5h-26.3zM349.3 105.5a3.4 3.4 0 0 1 3.4-3.4 3.4 3.4 0 1 1 0 6.7 3.4 3.4 0 0 1-3.4-3.3zm.9 40.3h5v-32.4h-5zM363.5 154l2.5-3.6c2.7 3.1 5.9 4.3 10.5 4.3s10.5-2.6 10.5-9.4v-4.9a13.8 13.8 0 0 1-10.9 5.8c-8.5 0-14.4-6.3-14.4-16.8s5.9-16.8 14.4-16.8a13.7 13.7 0 0 1 10.9 5.7v-4.9h5v31.9c0 10.2-7.6 13.6-15.5 13.6-5.4 0-9.1-1.1-13-4.9m23.5-17.5v-14.2a12.2 12.2 0 0 0-9.7-5.2c-6.5 0-10.4 5.2-10.4 12.3s3.9 12.3 10.4 12.3a12.2 12.2 0 0 0 9.7-5.2M422.2 145.8v-21.3c0-5.8-2.9-7.4-7.3-7.4a12.5 12.5 0 0 0-9.5 5v23.7h-5v-43.1h5v15.4a15.6 15.6 0 0 1 11.5-5.5c6.8 0 10.3 3.3 10.3 10.3v22.9zM436.8 139v-21.1h-5.3v-4.5h5.3v-10.7h5.1v10.7h6.6v4.5h-6.6v20c0 2.4 1.1 4.2 3.2 4.2a4.7 4.7 0 0 0 3.4-1.4l1.5 3.8a8.3 8.3 0 0 1-6 2.1c-4.8 0-7.2-2.8-7.2-7.6"
    />
  </svg>
)

LogoWithText.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

LogoWithText.defaultProps = {
  width: 200,
  height: 50
}

LogoWithText.displayName = 'LogoWithText'

export const Logo = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
    <path
      fill="#2589ca"
      d="M467.9 338.1a226.7 226.7 0 0 0 15.4-82.3 227.3 227.3 0 1 0-454.6 0A226.7 226.7 0 0 0 44.1 338L256 165.4z"
    />
    <path
      fill="#444445"
      d="M256 242l183 149.1a227.3 227.3 0 0 1-366 0zm-6.8 97.4h-37.8v37.8h37.8zm50.4 0h-37.8v37.8h37.8zM249.2 390h-37.8v37.8h37.8zm50.4 0h-37.8v37.8h37.8z"
    />
    <path fill="#2589ca" d="M256 348z" />
  </svg>
)

Logo.displayName = 'Logo'

export const Spinner = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="20 20 60 60"
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" fill="currentColor">
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.9166666666666666s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="47"
      y="24"
      rx="9.4"
      ry="4.8"
      width="6"
      height="12"
      fill="currentColor"
      transform="rotate(30 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.8333333333333334s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="47"
      y="24"
      rx="9.4"
      ry="4.8"
      width="6"
      height="12"
      fill="currentColor"
      transform="rotate(60 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.75s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="47"
      y="24"
      rx="9.4"
      ry="4.8"
      width="6"
      height="12"
      fill="currentColor"
      transform="rotate(90 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.6666666666666666s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="47"
      y="24"
      rx="9.4"
      ry="4.8"
      width="6"
      height="12"
      fill="currentColor"
      transform="rotate(120 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.5833333333333334s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="47"
      y="24"
      rx="9.4"
      ry="4.8"
      width="6"
      height="12"
      fill="currentColor"
      transform="rotate(150 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.5s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="47"
      y="24"
      rx="9.4"
      ry="4.8"
      width="6"
      height="12"
      fill="currentColor"
      transform="rotate(180 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.4166666666666667s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="47"
      y="24"
      rx="9.4"
      ry="4.8"
      width="6"
      height="12"
      fill="currentColor"
      transform="rotate(210 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.3333333333333333s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="47"
      y="24"
      rx="9.4"
      ry="4.8"
      width="6"
      height="12"
      fill="currentColor"
      transform="rotate(240 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.25s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="47"
      y="24"
      rx="9.4"
      ry="4.8"
      width="6"
      height="12"
      fill="currentColor"
      transform="rotate(270 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.16666666666666666s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="47"
      y="24"
      rx="9.4"
      ry="4.8"
      width="6"
      height="12"
      fill="currentColor"
      transform="rotate(300 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.08333333333333333s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="47"
      y="24"
      rx="9.4"
      ry="4.8"
      width="6"
      height="12"
      fill="currentColor"
      transform="rotate(330 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="0s"
        repeatCount="indefinite"
      />
    </rect>
  </svg>
)

Spinner.displayName = 'Spinner'

export const Paddle = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className={className}>
    <path
      fill="currentColor"
      d="M496.2 296.5C527.7 218.7 512 126.2 449 63.1 365.1-21 229-21 145.1 63.1l-56 56.1 211.5 211.5c46.1-62.1 131.5-77.4 195.6-34.2zm-217.9 79.7L57.9 155.9c-27.3 45.3-21.7 105 17.3 144.1l34.5 34.6L6.7 424c-8.6 7.5-9.1 20.7-1 28.8l53.4 53.5c8 8.1 21.2 7.6 28.7-1L177.1 402l35.7 35.7c19.7 19.7 44.6 30.5 70.3 33.3-7.1-17-11-35.6-11-55.1-.1-13.8 2.5-27 6.2-39.7zM416 320c-53 0-96 43-96 96s43 96 96 96 96-43 96-96-43-96-96-96z"
    />
  </svg>
)

Paddle.propTypes = {
  className: PropTypes.string
}

Paddle.defaultProps = {
  className: undefined
}

Paddle.displayName = 'Paddle'

export const Graphic = {
  Canary,
  Logo,
  LogoWithText,
  Paddle,
  Spinner
}
