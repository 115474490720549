import { ChangeEvent, ComponentPropsWithoutRef } from 'react'

export type SelectInputProps = {
  value?: string
  options: Array<{ name: string; value: number }>
  onChange?: (val: number) => void
  onBlur?: (val: number) => void
}

export const SelectInput = ({
  value,
  options,
  onChange,
  onBlur,
  ...props
}: SelectInputProps & Omit<ComponentPropsWithoutRef<'select'>, 'onChange'>) => {
  const handleOnChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.currentTarget.value || ''
    onChange?.(Number(value))
  }
  const handleOnBlur = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.currentTarget.value || ''
    onBlur?.(Number(value))
  }
  return (
    <select value={Number(value) || ''} onChange={handleOnChange} onBlur={handleOnBlur} {...props}>
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.name}
        </option>
      ))}
    </select>
  )
}
