import { ButtonHTMLAttributes, ReactNode } from 'react'
import { cx } from 'utils'
import { Icon } from 'components/Icon'
import { Spinner } from 'components/Graphic'
import styles from './Button.module.scss'

export type ButtonTypes =
  | 'action'
  | 'cancel'
  | 'create'
  | 'external'
  | 'icon'
  | 'iconInverted'
  | 'link'
  | 'menuButton'
  | 'primary'
  | 'secondary'
  | 'submit'
  | 'tab'
  | 'toggle'
  | 'wrapper'

export type ButtonProps = {
  children?: ReactNode
  className?: string
  text?: string
  large?: boolean
  size?: 'large' | 'medium' | 'small'
  as?: ButtonTypes
  disabled?: boolean
  submitting?: boolean
  noBorder?: boolean
  isBlock?: boolean
}

export const Button = ({
  children,
  text,
  className,
  as,
  large,
  size,
  submitting,
  disabled,
  noBorder = false,
  isBlock = false,
  ...props
}: ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>) => {
  let asClass = null
  let dataTestId = ''
  let buttonType: 'button' | 'submit' | 'reset' = 'button'
  let icon

  switch (as) {
    case 'create':
      asClass = styles.create
      dataTestId = 'create-button'
      break
    case 'primary':
      asClass = styles.primary
      dataTestId = 'primary-button'
      break
    case 'submit':
      asClass = styles.primary
      dataTestId = 'submit-button'
      buttonType = 'submit'
      break
    case 'cancel':
      asClass = styles.transparent
      dataTestId = 'cancel-button'
      break
    case 'action':
      asClass = styles.action
      dataTestId = 'action-button'
      break
    case 'link':
      asClass = styles.link
      dataTestId = 'link-button'
      break
    case 'tab':
      dataTestId = 'tab-button'
      break
    case 'secondary':
      asClass = styles.secondary
      dataTestId = 'secondary-button'
      break
    case 'external':
      asClass = styles.external
      dataTestId = 'external-button'
      icon = <Icon.ExternalLink />
      break
    case 'menuButton':
      asClass = styles.menuButton
      dataTestId = 'menu-button'
      break
    case 'icon':
      asClass = styles.icon
      dataTestId = 'icon-button'
      break
    case 'iconInverted':
      asClass = styles.iconInverted
      dataTestId = 'icon-inverted-button'
      break
    case 'wrapper':
      asClass = styles.wrapper
      dataTestId = 'wrapper-button'
      break
    case 'toggle':
      asClass = styles.toggle
      dataTestId = 'toggle-button'
      break
    default:
      asClass = null
      dataTestId = 'button'
  }

  const sizeClass = (() => {
    if (large || size === 'large') {
      return styles.large
    }
    if (size === 'medium') {
      return styles.medium
    }
    return null
  })()

  const noBorderClass = noBorder ? styles.noBorder : null
  const isBlockClass = isBlock ? styles.isBlock : null
  /* eslint-disable react/button-has-type */
  return (
    <button
      type={buttonType}
      data-testid={dataTestId}
      disabled={disabled || submitting}
      className={cx(styles.button, sizeClass, asClass, noBorderClass, isBlockClass, className)}
      {...props}
    >
      {submitting ? <Spinner height={16} width={16} className={styles.spinner} /> : null}
      {text}
      {children}
      {icon}
    </button>
  )
}
